import React, { useState } from "react";

import styled from "styled-components";
import Share from "./share";
import Audio from "./audio";

const Element = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 12px;
  background: #fff;
  border: 2px solid #ffffff;

  &.hover {
    border: 2px solid #0f2bc5;
  }
`;

const Number = styled.div``;

const NumberImage = styled.img``;

const Title = styled.div`
  width: 100%;
  color: #0f2bc5;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  margin-left: 16px;
  margin-right: 16px;
`;

const Arrow = styled.div`
  width: 42px;
  height: 42px;
`;

const ArrowImage = styled.div`
  width: 42px;
  height: 42px;
  display: block;
  background: url(/img/closed.svg) center center no-repeat;
  background-size: contain;
  cursor: pointer;

  &:hover,
  &.open {
    background: url(/img/open.svg) center center no-repeat;
  }
`;

const Hidden = styled.div`
  height: 0px;
  overflow: hidden;
  margin-bottom: 16px;
  background: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const HolderBlock = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  transition: 1s;
`;

const BlockImage = styled.img`
  width: 100%;
`;

const Block = styled.div`
  width: 50%;

  &.padding {
    padding: 16px;
  }
`;

const Tweet = styled.div`
  border-radius: 12px;
  border: 1px solid #b9b9b9;
  background: #fff;
`;

const TweetAvatar = styled.img`
  width: 100px;
`;

const TweetTitle = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

const TweetText = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const TweetBlock = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const Download = styled.div`
  color: rgb(27, 100, 243);
  margin-bottom: 54px;
  font-size: 24px;
  cursor: pointer;
  text-decoration: underline;
`;

export default function Player({
  children,
  number,
  title,
  tweet,
  isMobile,
  audioImage,
  audioTitle,
  audioSubtitle,
}) {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <Element
        style={{
          padding: isMobile ? "16px" : "14px 32px",
          borderBottomLeftRadius: open ? 0 : 12,
          borderBottomRightRadius: open ? 0 : 12,
        }}
        onClick={toggle}
        id={`objetivo_${number}`}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        className={hover ? (open ? "" : "hover") : ""}
      >
        <Number
          style={{ width: isMobile ? 63 : 111, height: isMobile ? 63 : 120 }}
        >
          <NumberImage
            src={`/img/0${number}.png`}
            style={{ width: isMobile ? 63 : 111, height: isMobile ? 63 : 120 }}
          />
        </Number>
        <Title style={{ fontSize: isMobile ? 18 : 32 }}>{title}</Title>
        <Arrow>
          <ArrowImage className={open ? "open" : ""} onClick={toggle} />
        </Arrow>
      </Element>
      <Hidden
        style={{
          height: open ? "auto" : 0,
          padding: isMobile ? (open ? 16 : 0) : open ? 32 : 0,
        }}
      >
        <HolderBlock
          style={{
            display: isMobile ? "block" : "flex",
            opacity: open ? 1 : 0,
          }}
        >
          <Block style={{ width: isMobile ? "100%" : "45%" }}>
            <BlockImage
              src={`/img/${number}.png`}
              style={{ width: isMobile ? "100%" : "90%" }}
            />
          </Block>
          <Block style={{ width: isMobile ? "100%" : "55%" }}>
            <Tweet
              style={{
                padding: isMobile ? 16 : 32,
                marginBottom: isMobile ? 24 : 54,
              }}
            >
              <TweetBlock>
                <TweetAvatar src={`/img/avatar.png`} />
                <Share
                  center={false}
                  url={`https://brasil2045.com.br/?p=objetivo_${number}`}
                  text={`${title}: venha escutar o que o PSDB tem a dizer sobre o tema`}
                  id={`Audio ${number}`}
                />
              </TweetBlock>
              <TweetTitle style={{ fontSize: isMobile ? 18 : 24 }}>
                Em um tuíte
              </TweetTitle>
              <TweetText style={{ fontSize: isMobile ? 18 : 24 }}>
                {tweet}
              </TweetText>
            </Tweet>
            {children}
            <Download
              onClick={() => {
                document
                  .getElementById("download")
                  .scrollIntoView({ block: "start", behavior: "smooth" });
              }}
            >
              Conheça a agenda completa
            </Download>
            <Audio
              isMobile={isMobile}
              audioImage={audioImage}
              title={title}
              audioTitle={audioTitle}
              audioSubtitle={audioSubtitle}
              audioFile={`/audios/${number}.mp3`}
              // audioFile={`/audios/1.mp3`}
              number={number}
            />
          </Block>
        </HolderBlock>
      </Hidden>
    </>
  );
}
