import React from "react";

import styled from "styled-components";

const Title = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
`;

const SubTitle = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  margin-bottom: 16px;
`;

// const Bold = styled.span`
//   font-weight: 700;
// `;

export default function Seven({ isMobile }) {
  return (
    <>
      <Title style={{ fontSize: isMobile ? 18 : 24 }}>Tucanês</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        Trabalhamos para a prevenção e redução de violência. Sistema
        penitenciário reestruturado, modernização e valorização das carreiras
        policiais, uso de tecnologia de ponta (inteligência artificial) e
        políticas públicas efetivas com base em dados e evidências.
      </SubTitle>
      {/* <Title style={{ fontSize: isMobile ? 18 : 24 }}>Aprofundamento</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Priorizar medidas de controle e prevenção de crimes</Bold>
        <br />- Diferenciação de usuários de traficantes de drogas, a partir da
        avaliação de experiências de outros países
        <br />- Reorientação da atuação policial para o combate de crimes
        violentos e da criminalidade organizada e transnacional e não do
        microtraficante
        <br />- Apoio a programas de bonificação por metas para as polícias
        <br />- Criação de um sistema de atendimento de emergência e
        pronta-resposta com profissionais não policiais
        <br />- Garantia de marcação de todas as munições comercializadas no
        país.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Investir em tecnologia, monitoramento e inteligência artificial para
          transparência, governança e prestação de contas:
        </Bold>
        <br />- Criação de central de inteligência policial integrada com bancos
        de dados criminais, utilizando georreferenciamento, gestão e
        compartilhamento, e transparência dos dados da segurança pública nos
        estados e no plano federal.
        <br />- Garantia ao atendimento de ocorrências via despacho operacional
        na região de fronteira, em especial ao combate ao tráfico de drogas,
        armas e contrabandos
        <br />- Unificação e padronização das metodologias de cálculo aplicadas
        ao Pacto pela Vida, bem com a convergência das bases de dados de
        segurança pública e penitenciários no Brasil, com cruzamento de bases
        confirmatórias
        <br />
        -- Foco na qualificação de informações em parcerias com entidades
        não-governamentais para busca por consensos amplos entre os agentes
        envolvidos, aprimorando indicadores, estatísticas de violência e
        monitoramento das organizações de segurança do Estado e justiça
        criminal.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Combater crimes violentos nas redes de ensino:</Bold>
        <br />- Criação de uma estrutura permanente com monitoramento e
        inteligência, particularmente no âmbito federal, para frustrar ataques e
        evitar massacres nas redes de ensino.
        <br />- Fomento à Patrulha Escolar Comunitária, para aproximar a polícia
        da comunidade, com estímulo à prevenção da violência e intuito de
        combater a criminalidade nas redes de ensino e arredores.
        <br />- Treinamento de professores, agentes de saúde e assistentes
        sociais em metodologias de mediação de conflitos, fortalecimento de
        habilidades parentais e detecção precoce de doenças mentais e
        dependência química, para encaminhamento aos serviços públicos
        especializados.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Combater firmemente crimes de violência sexual contra mulheres e
          crianças
        </Bold>
        <br />- Garantia da efetividade da Lei Maria da Penha (violência
        doméstica), da Lei do Feminicídio, da Lei n.o 13.431/2017 (que
        estabelece o sistema de garantia de direitos da criança e do adolescente
        vítimas ou testemunhas de violência) e do princípio da organização de
        atendimento público especializado para mulheres e vítimas de violência
        doméstica no SUS (Lei n.o 13.427).
        <br />- Advogar uma melhor infraestrutura urbana com acessos seguros e
        iluminação
        <br />- Fortalecimento e expansão da Casa da Mulher e projetos de
        Patrulha Maria da Penha
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Investir em programas de valorização das polícias, </Bold>
        incluindo a melhoria das condições de trabalho, regras claras de
        promoção e alocação em cargos de chefia e os cuidados com a saúde
        mental.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Melhorar as estruturas institucionais de segurança e sua cooperação:
        </Bold>
        <br />- Implantação do Ministério da Segurança Pública e o Sistema Único
        de Segurança Pública (SUSP)
        <br />- Promoção de cooperação permanente entre os Ministérios da
        Segurança Pública e da Defesa
        <br />- Forças Armadas: atualização de regras e limites para
        participação da ativa e da reserva em cargos do governo,
        <br />- Ministério da Defesa:
        <br />
        -- Criação de um 4o comando, além dos da Marinha, Exército e
        Aeronáutica, o Cibernético, a exemplo do que já ocorre em diversos
        países.
        <br />
        -- Realização de concursos para especialistas e gestores públicos civis
        em defesa.
        <br />
        -- Centralização das compras das Forças em um único órgão, a Secretaria
        de Produtos de Defesa (Seprod), para a melhorar a interoperabilidade e
        agilidade.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Propor nova legislação para as Guardas Municipais e fortalecer o papel
          dos municípios na segurança pública
        </Bold>
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Focar em medidas penitenciárias e socioeducativas</Bold>
        <br />- Apoio aos estados na criação de centros de detenção provisória
        para presos que aguardam julgamento, liberando a Polícia Civil para o
        trabalho de investigação
        <br />- Investimento em infraestrutura de presídios, proporcionando
        trabalho e estudo para presos e egressos, além de outros programas para
        diminuição da reincidência
        <br />- Criação da carreira de agente correcional para acompanhar
        medidas cautelares, saídas e egressos dos sistemas prisional e
        socioeducativo
        <br />- Implementação de políticas de prevenção e reabilitação, como
        políticas de desestímulo ao uso de drogas e apoio às comunidades
        terapêuticas, assim como estabelecimento de redes de atendimento a
        vítimas de violência, como mulheres e grupos minorizados.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Fortalecer as forças de segurança do país com ações concretas e
          imediatas
        </Bold>
        <br />- Criação da Guarda Nacional, uma polícia militar federal
        <br />- Aumento do número de prisões federais, especialmente para
        líderes do crime organizado
        <br />- Aprimoramento da gestão, integração operacional e formação
        profissional das polícias com base em novas tecnologias e inteligência
        artificial
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Reduzir a impunidade do crime ambiental no Brasil</Bold>
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        #controleeprevençãodecrimes #combatecrimesviolentos
        #diferenciarusuárioetraficante #marcaçãodemunições
        #investiremtecnologiaeAI #monitoramentodedados
        #combateviolêncianasredesdeensio
        #combateviolênciasexualcontrameninasemulheres #valorizaçãodaspolícias
        #melhorescondiçõestrabalhoparapoliciais
        #melhorarestruturasinstitucionais #fortalecerpapelmunicípios
        #medidaspenitenciáriassocioeducativas #forçasdesegurançanacional
        #nãoaimpunidadeambiental
      </SubTitle> */}
    </>
  );
}
