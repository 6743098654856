import React from "react";

import styled from "styled-components";

const Title = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
`;

const SubTitle = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  margin-bottom: 16px;
`;

// const Bold = styled.span`
//   font-weight: 700;
// `;

export default function Six({ isMobile }) {
  return (
    <>
      <Title style={{ fontSize: isMobile ? 18 : 24 }}>Tucanês</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        Acreditamos na liberdade do indivíduo. O Brasil com Estado secular,
        multicultural e plurirracial deve respeitar todas matizes religiosas e
        liberdades individuais, assim como fazer políticas afirmativas, que
        equilibrem as relações sociais, para que grupos historicamente privados
        de direitos no Brasil possam prosperar com igualdade de oportunidades.
      </SubTitle>
      {/* <Title style={{ fontSize: isMobile ? 18 : 24 }}>Aprofundamento</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Proteger as liberdades fundamentais</Bold> das pessoas, para
        pensar, se expressar, associar, movimentar e garantir acesso às
        informações de interesse público.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Promover e garantir o cumprimento de leis (artigo 5o da Constituição)
          que busquem a melhoria da qualidade de vida e a não discriminação e
          violência contra grupos minorizados,{" "}
        </Bold>{" "}
        como pessoas com deficiência, negros, indígenas, mulheres, LGBTQIAPN+,
        imigrantes e refugiados, jovens e idosos.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Realizar reformas para dar aos grupos minorizados direitos iguais aos
          recursos econômicos,
        </Bold>{" "}
        bem como o acesso à propriedade, reconhecendo a transversalidade da
        dificuldade do acesso ao capital e garantindo que sejam ferramentas
        chave para atingimento de capacidades.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Combater o etarismo, </Bold>os maus tratos e promovendo o acesso à
        moradia, educação e cursos profissionalizantes, com foco na inclusão
        digital e reinserção no mercado de trabalho. Pautar as políticas
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Desenvolver um conceito de secularismo adequado à realidade
          brasileira,{" "}
        </Bold>
        que proteja a liberdade religiosa individual e coletiva, incluindo
        agnósticos e ateus.
        <br />- Nova arquitetura institucional para promoção da participação
        positiva de grupos religiosos no debate público, incentivando o debate
        inter-religioso, combatendo o discurso de ódio e atualizando o
        entendimento coletivo sobre como religião e democracia podem se
        relacionar de maneira positiva.
        <br />- Combate a excessos e ataques às religiões
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Fortalecer referências de políticas afirmativas</Bold>
        <br />- Garantia de políticas focadas em corrigir desigualdades
        estruturais através medidas propensas a agenciar a igualdade de
        oportunidades para integrantes de grupos sociais vulnerabilizados,
        excluídos e/ou marginalizados, na história da construção da sociedade
        brasileira, visando equidade de oportunidades racial e social, bem como
        promoção de uma sociedade mais justa, igual, que erradique a pobreza e
        reduza as desigualdades como determina a Constituição Federal em seu
        artigo 3o, buscando assim, a equidade, e colaborando para a mobilidade
        social desses grupos que, até hoje, padecem de uma efetiva igualdade de
        fato.
        <br />
        -- Manutenção das cotas étnica-racial para acesso às universidades
        públicas e melhoria nos mecanismos e na universalização de
        heteroidentificação racial.
        <br />
        -- Criação de cotas de participação do mercado de trabalho em cargos de
        comando e conselhos de empresas privadas e empresas de capital aberto
        para mulheres, negros e indígenas promovendo maior inserção ao capital e
        melhores oportunidades no mercado de trabalho.
        <br />
        -- Promover estímulo às candidaturas negras por meio de alocação direta
        de recursos de formação e eleitoral.
        <br />
        -- Fortalecimento da implementação de inclusão da população com
        deficiência no mercado de trabalho por ampliação de campanhas de
        conscientização e recursos para qualificação específica.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Implementar políticas anti racistas de maneira interseccional
        </Bold>{" "}
        para garantia dos direitos e acesso aos espaço de poder
        <br />- Reformulação de currículos para que as relações étnicos-raciais
        estejam presentes de maneira específica e transversal em todas as etapas
        das políticas educacionais
        <br />- Combate ao racismo institucional por meio de visualização do
        compromisso institucional com a causa, políticas afirmativas com metas e
        objetivos de curto prazo, inclusão de instâncias de governança, produção
        de dados e informações cadastrais, manifestações claras ao público em
        geral e avaliação das políticas e serviços
        <br />- Fortalecimento de movimentos e organizações antirracistas a
        partir do apoio da atuação de grupos que lutam contra o racismo e
        promovem a igualdade racial, como o Tucanafro.
        <br />- Combate ao discurso de ódio e intolerância com ações de
        prevenção e punição contra manifestações racistas em ambientes públicos
        e online.
        <br />- Sensibilização e conscientização por meio de campanhas para
        combater preconceitos e estereótipos raciais na sociedade.
        <br />- Investimento em programas de ressocialização no âmbito da
        justiça e administração penitenciária promovendo efetivação da justiça
        social restaurativa, acometida por maioria de negros.
        <br />- Restabelecimento de orçamentos para preservação e proteção de
        comunidades quilombolas, tradicionais, indígenas, ribeirinhas, das
        florestas e dos terreiros.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Cobrar a coleta, análise, publicação e disseminação de dados
          estatísticos,{" "}
        </Bold>
        confiáveis em níveis local, regional e nacional sobre a população negra,
        baseadas no princípio da autodeclaração
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Promover a participação ativa de pessoas com deficiência na sociedade
        </Bold>
        <br />- Inserção no mercado de trabalho, valorizando as suas
        potencialidades, com qualificação e requalificação profissional
        <br />- Promoção de centros de referência regionalizados para
        atendimento qualificado e integral a pessoas com deficiência.
        <br />- Apoio estruturantes a mães no cuidado de filhos atípicos de
        maneira multidisciplinar (educação e saúde)
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Implementar políticas para meninas e mulheres, </Bold>construindo
        um novo paradigma social de equidade de gênero
        <br />- Investimento ao empreendedorismo feminino: qualificação e
        microcrédito facilitado para que as mulheres possam empreender
        <br />- Fortalecimento da abordagem correta da interseccionalidade de
        gênero nas políticas públicas: perspectiva de gênero nos planos,
        orçamentos e estatísticas
        <br />- Aprimoramento das legislações federal e estaduais, para paridade
        de gênero nos espaços de liderança, de poder e tomada de decisão
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Implementar e fortalecer a Lei de Migração</Bold> no que tange a
        recursos e infraestrutura, processos de regularização, cooperação
        internacional e combate à discriminação e xenofobia.
        <br />- Promoção de parcerias público-privadas para fortalecer a
        infraestrutura necessária para lidar com o fluxo de migrantes, incluindo
        a criação de espaços adequados para acolhimento e integração.
        <br />- Simplificação dos processos de regularização migratória,
        reduzindo a burocracia e facilitando o acesso dos migrantes à
        documentação necessária.
        <br />- Estabelecimento de programas de apoio e assistência para os
        migrantes durante o processo de regularização, a fim de reduzir as
        barreiras enfrentadas e promover sua integração social e econômica.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Proteger a liberdade de acesso à informação e defesa do debate público
          sadio,{" "}
        </Bold>{" "}
        combatendo as fake news e regulamentando as redes sociais:
        <br />- Processo amplo e justo de discussão, com escuta de todos atores
        de interesse, para formular regulamentação que endereçe desafios de
        nosso tempo e realidade brasileira, assegurando um equilíbrio ótimo
        entre os papéis do Estado, empresas e sociedade civil
        <br />- Garantia da proteção de dados e transparência na publicidade
        direcionada
        <br />
        -- Garantir monitoramento e responsabilidade em relação ao tratamento e
        análise de dados por parte do setor público e privado
        <br />
        -- Proibir o uso indevido de dados, sem justificativas para fins de
        investigação
        <br />
        -- Assegurar transparência do posicionamento de plataformas e
        publicações
        <br />- Adaptação das leis eleitorais à era digital e garantia de
        transparência na propaganda política online
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Defender os interesses de grupos minorizados</Bold> de maneira
        estruturada e orientada a longo prazo, fomentando a democracia
        intrapartidária, garantindo que os Segmentos Tucanos tenham voz
        deliberativa nos diferentes níveis federativos, seguindo o exemplo do
        caso nacional logo quando de sua criação
      </SubTitle> */}
    </>
  );
}
