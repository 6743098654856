import React from "react";

import styled from "styled-components";

const Title = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
`;

const SubTitle = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  margin-bottom: 16px;
`;

// const Bold = styled.span`
//   font-weight: 700;
// `;

export default function Twelve({ isMobile }) {
  return (
    <>
      <Title style={{ fontSize: isMobile ? 18 : 24 }}>Tucanês</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        Reafirmamos os valores da democracia e da universalidade dos direitos
        humanos nas relações internacionais. Buscamos o desenvolvimento do país
        nos planos econômico, ambiental, social e cultural por meio de uma
        inserção multilateral e pragmática, respeitando os princípios
        tradicionais da política externa brasileira.
      </SubTitle>
      {/* <Title style={{ fontSize: isMobile ? 18 : 24 }}>Aprofundamento</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Manter o compromisso com a tradição da diplomacia brasileira,
          promovendo os valores de respeito, tolerância e equilíbrio.
        </Bold>{" "}
        É preciso reafirmar os princípios norteadores da política externa,
        inscritos na Constituição, como compromissos do Estado Brasileiro, bem
        como respeitar e valorizar a excelência profissional do nosso corpo
        diplomático.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Restabelecer o firme compromisso com o repúdio às tiranias, o direito
          à paz, a solidariedade internacional em defesa da democracia,
        </Bold>{" "}
        o respeito aos direitos humanos e a conservação do meio ambiente como
        traços marcantes da identidade internacional do Brasil.
        <br />- Definição de democracia como um regime que transforma as visões
        populares em políticas públicas ao mesmo tempo em que protege os
        direitos individuais, ou seja, os direitos das minorias.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Buscar relacionamentos amplos, universais, sem exclusão ou
          alinhamentos automáticos,
        </Bold>{" "}
        sempre orientado ao multilateralismo e independência em relação às
        principais potências.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Garantir que a diplomacia brasileira seja a grande protagonista da
          economia verde
        </Bold>{" "}
        com foco na proteção ambiental, descarbonização produtiva, venda de
        crédito de carbono, respeitando o meio ambiente, a partir de soluções
        tecnológicas compartilhadas com grandes atores mundiais.
        <br />- Garantia que a gestão da Amazônia, bioma compartilhado com
        nações amigas, receba atenção especial. O meio ambiente e o
        desenvolvimento sustentável são grandes ativos do Brasil.
        <br />- Cumprimento das metas assumidas no Acordo de Paris e cobrança
        para que demais países cumpram o acordado
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Promover o desenvolvimento nacional:</Bold> priorizar o
        desenvolvimento econômico, social e cultural do país na formulação da
        política externa.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Promover a abertura econômica e garantir que o comércio exterior seja
          relevante no PIB brasileiro,
        </Bold>{" "}
        com objetivo de retomar a agenda de competitividade do país, a partir da
        assinatura de tratados comerciais competitivos
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Ampliar a influência regional:</Bold> dar prioridade à América do
        Sul como cenário imediato de atuação, promovendo integração com os
        vizinhos e fortalecendo laços com o Mercosul e a Aliança para o
        Pacífico.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Fortalecer a cooperação com organismos internacionais,</Bold> como
        a Organização Internacional para as Migrações (OIM) e o Alto
        Comissariado das Nações Unidas para os Refugiados (ACNUR), visando a
        troca de conhecimentos, melhores práticas e assistência técnica em
        questões migratórias.
        <br />- Estabelecimento de parcerias bilaterais e multilaterais com
        outros países, a fim de promover a cooperação no combate ao tráfico de
        pessoas, facilitar a repatriação digna e promover ações conjuntas para a
        integração dos migrantes.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Garantir a utilização dos Objetivos do Desenvolvimento Sustentável
          (ODS),
        </Bold>{" "}
        como referências no relacionamento externo brasileiro.
        <br />- Aumentar a cooperação e ampla participação da sociedade civil
        para cumprimento dos objetivos e metas dos Objetivos de Desenvolvimento
        Sustentável (ODS) e demais desafios internacionais.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        #tradiçãodiplmáticabrasileira #respeitotolerânciaeequilibrio
        #repúdioàstiranias #defesademocracia #ODScomoreferência
        #promoverdesenvolvimentonacional #relacionamentoamploeuniversal
        #semalinhamentoautomático #ampliarinfluênciaregional #fortalecerMercoSul
        #fortalecerAliançaparaoPacífico #aberturaeconômica
        #comércioexteriorrelevantenoPIB #gestãodaAmazônia #dplomaciaprotagonista
        #protagonistaeconomiaverde
      </SubTitle> */}
    </>
  );
}
