import React from "react";

import styled from "styled-components";

const Element = styled.div`
  width: 100%;
  height: auto;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0px 16px;
  margin-bottom: 24px;
`;

const Left = styled.div`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #0f2bc5;
  background: #fff;
  height: 100%;
  text-align: center;
`;

const Right = styled.div`
  border-radius: 12px;
  border: 1px solid #0f2bc5;
  background: #fff;
  height: 100%;
`;

const Image = styled.img`
  width: 350px;
`;

const Title = styled.div`
  color: #565656;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 16px;
`;

const SubTitle = styled.div`
  color: #565656;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export default function Value({ inverted, isMobile, image, title, text }) {
  return (
    <Element style={{ display: isMobile ? "block" : "flex" }}>
      {inverted && !isMobile ? (
        <>
          <Right
            style={{
              width: isMobile ? "100%" : "auto",
              marginRight: isMobile ? 0 : 24,
              padding: isMobile ? 24 : 32,
            }}
          >
            <Title style={{ fontSize: isMobile ? 24 : 54 }}>{title}</Title>
            <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>{text}</SubTitle>
          </Right>
          <Left
            style={{
              width: isMobile ? "100%" : "auto",
              marginBottom: isMobile ? 24 : 0,
            }}
          >
            <Image src={image} style={{ maxWidth: isMobile ? "100%" : "" }} />
          </Left>
        </>
      ) : (
        <>
          <Left
            style={{
              width: isMobile ? "100%" : "auto",
              marginBottom: isMobile ? 24 : 0,
              marginRight: isMobile ? 0 : 24,
            }}
          >
            <Image src={image} style={{ maxWidth: isMobile ? "100%" : "" }} />
          </Left>
          <Right
            style={{
              width: isMobile ? "100%" : "auto",
              padding: isMobile ? 24 : 32,
            }}
          >
            <Title style={{ fontSize: isMobile ? 24 : 54 }}>{title}</Title>
            <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>{text}</SubTitle>
          </Right>
        </>
      )}
    </Element>
  );
}
