import React from "react";

import styled from "styled-components";

const Title = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
`;

const SubTitle = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  margin-bottom: 16px;
`;

// const Bold = styled.span`
//   font-weight: 700;
// `;

export default function Eight({ isMobile }) {
  return (
    <>
      <Title style={{ fontSize: isMobile ? 18 : 24 }}>Tucanês</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        Investir em ciência e tecnologia é investir no futuro, é o investimento
        na educação e no emprego de manhã. Destinar ao menos 2% do PIB para o
        setor, nos próximos 5 anos, é crucial para inovar na indústria e em
        biotecnologia.
      </SubTitle>
      {/* <Title style={{ fontSize: isMobile ? 18 : 24 }}>Aprofundamento</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Incentivar o investimento em inovação</Bold>, inclusive como saída
        prioritária para os ambientes de crise181, desenvolvendo estratégias
        voltadas <Bold>para a competição em mercados globais</Bold> e para a
        resolução de problemas complexos182, atentos à ética e à transparência.
        <br />- Revisão estratégica do setor, para garantir visão unificada de
        longo prazo, evitando soluções “remendadas” e organizando o setor de
        maneira transversal do estratégico ao operacional
        <br />- Garantia de que os dados sejam abertos, compartilhados em um
        ambiente que favoreça construções com diferentes atores
        <br />- Continuidade da pesquisa básica para garantir conhecimento como
        bem público.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Pautar a inovação no formato “SandBox” em todo setor,</Bold> que
        consiste em ambiente controlado e seguro para teste e desenvolvimento de
        novas tecnologias, permitindo que desenvolvedores e empreendedores
        tenham liberdade de experimentar sem medo de causar danos irreparáveis
        em um ambiente real.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Estimular a conexão com centros geradores de ciência mundial,
        </Bold>{" "}
        revigorando ações de parceria com universidades e institutos de
        referência184, a partir de programas de ciência e tecnologia focados na
        pesquisa aplicada com estímulos visíveis na indução de parceria entre
        universidades, institutos de pesquisa e empresas de maneira contínua e
        intensa, locais e internacionais.
        <br />- Revisão do formato das universidades brasileiras, para que se
        tornem centro de produção e instrumento público-privado.
        <br />- Promoção de mudança da cultura de um artigo como um bem
        finalístico para o pesquisador e a academia para que o conhecimento se
        transforme em riqueza.
        <br />- Mudança na forma de se mensurar a produtividade acadêmica, de
        modo a dar a devida atenção ao impacto econômico-social produzido.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Investir em Inteligência Artificial como prioridade para o
          desenvolvimento econômico e redução da lacuna de renda no Brasil.
        </Bold>
        <br />- Garantia dos princípios éticos, remoção de barreiras legais e
        colaboração entre público e privado
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Assegurar a proteção de dados, com regras simples para operar dados;
          buscando deixar o Brasil como líder em dados abertos
        </Bold>
        <br />- Ampliação e apoio para melhor implementação da Lei Geral de
        Proteção de Dados (LGPD)
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Formar e capacitar capital humano de qualidade,</Bold> investir em
        infraestrutura laboratorial e dar continuidade à adoção de políticas
        pública-privadas para a oferta de soluções tecnológicas escaláveis.
        <br />- Melhora da coordenação e das trocas de conhecimento com a
        criação de um comitê da Quarta Revolução Industrial sob o controle
        direto do PR. Este comitê, supostamente composto pelo governo,
        especialistas e empresas, ecoordenado pelo Ministério da Ciência, TIC e
        Planejamento Futuro, lideraria a formulação de políticas sobre as
        tecnologias da Quarta Revolução Industrial.
        <br />- Criação de uma organização que apóia pesquisa e desenvolvimento
        (P&D) para pequenos empreendimentos com assistência inicial do governo.
        <br />- Desenvolvimento e implementação de política nacional de
        desenvolvimento profissional para o futuro do trabalho.
        <br />- Estabelecimento de conselho profissional para apoiar o
        desenvolvimento de professores, e introduzir um quadro de competência
        nacional.
        <br />- Expansão do uso da Tecnologia Inovação e Comunicação (TIC) e da
        tecnologia online para a formação de professores.
        <br />- Fortalecimento da formação de professores por meio do
        desenvolvimento de docência em tecnologia aplicada, tendo o ensino como
        profissão de prestígio.
        <br />- Fortalecimento do desenvolvimento do professor na escola.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Pautar as políticas</Bold>
        <Bold>
          Construir um ambiente institucional favorável para indústria moderna
        </Bold>
        , tem por base a vocação brasileira para <Bold>bioeconomia</Bold>
        <br />- Reordenamento e modernização burocrática do Estado com foco em
        P&D.
        <br />- Demanda por insumos e oferta de produtos renováveis e
        sustentáveis com base na biologia (ex: produção de biomassa e produção
        de bioinsumos).
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Implementar uma política de incentivos claros e contínuos à indústria
          de base tecnológica
        </Bold>
        <br />- Elaboração de políticas que afetem diretamente a competitividade
        industrial, a captura de valor pelas empresas e a geração de empregos
        com melhor remuneração.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Criar o Sistema Nacional de Desenvolvimento Científico e Tecnológico
          (SNCTI)
        </Bold>
        <br />- Coordenação dos recursos e esforços dos agentes que atuam com
        inovação.
        <br />- Fortalecimento do Conselho Nacional de Desenvolvimento
        Científico e Tecnológico (CNPq)
        <br />- Empresa Brasileira de Pesquisa Agropecuária (Embrapa) e a
        Empresa Brasileira de Pesquisa e Inovação Industrial (Emprapii)
        integradas ao SNCTI na execução de seus esforços de pesquisa e
        desenvolvimento (P&D).
        <br />- Garantia do fim da pulverização e da fragilização de projetos.
        <br />- Robustez e foco nos programas de pesquisa setorial sempre em
        consonância com as demandas da sociedade.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Estimular o desenvolvimento do empreendedorismo e soluções escaláveis
          por meio das "startups" com o devido suporte institucional,
        </Bold>{" "}
        a partir de instrumentos contemporâneos de promoção da inovação, como
        atração de capital privado e internacional
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Investir em Infraestrutura Digital para garantir equidade de acesso e
          oportunidades
        </Bold>
        <br />- Garantia de acesso igualitário à internet e tecnologias para
        comunidades vulneráveis e periféricas, com atenção especial a grupos
        minorizados, reduzindo a exclusão digital
        <br />- Difusão das inovações e avanços já realizados como o Gov.br,
        concentrando ao máximo as informações para facilitar a usabilidade por
        parte dos cidadãos
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        #conhecimentoemriqueza #inovaçãosuperacrises #pesquisaemimpacto
        #conexãociênciamundial #industriamoderna #empreendedorismo #LGPD
        #inteligenciaartificial #bioeconomia #reduçãodedesigualdade
        #infraestruturadigital
      </SubTitle> */}
    </>
  );
}
