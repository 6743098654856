import React from "react";

import styled from "styled-components";

const Title = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
`;

const SubTitle = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  margin-bottom: 16px;
`;

// const Bold = styled.span`
//   font-weight: 700;
// `;

export default function Five({ isMobile }) {
  return (
    <>
      <Title style={{ fontSize: isMobile ? 18 : 24 }}>Tucanês</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        O destino do povo brasileiro não pode continuar sendo determinado pelo
        lugar de nascimento, independentemente de origem, credo, etnia ou cor,
        condição socioeconômica, orientação sexual, gênero, condições físicas,
        mentais, intelectuais ou sensoriais. Foco no desenvolvimento integral da
        população, com valorização do professor, garantindo o caminho desde a
        creche até o trabalho, inserindo o povo brasileiro na 4ª Revolução
        Industrial. O orçamento deve ser focado na educação básica, de maneira
        inovadora e priorizando a primeira infância.
      </SubTitle>
      {/* <Title style={{ fontSize: isMobile ? 18 : 24 }}>Aprofundamento</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Priorizar a primeira infância (0 a 6 anos), </Bold>
        garantindo acesso à creche para todas as crianças, inclusive por meio de
        parcerias com organizações filantrópicas ou instituições privadas.
        <br />- Foco em uma alfabetização e raciocínio matemático de primeira
        linha
        <br />- Ações de apoio às mães, desde a gestação, e às famílias com
        crianças pequenas, com acesso a serviços de cuidado acessíveis e de
        qualidade
        <br />- Ampliação da licença gestante ou instituição de uma licença
        parental, nos termos em que existe nos países social-democratas do norte
        europeu, advogando por uma mudança na legislação trabalhista.
        <br />- Apoio aos municípios na busca ativa de crianças para concluir a
        universalização da Pré-Escola (entre 4 e 5 anos).
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Focar na Educação Básica, assegurando qualidade e equidade</Bold>
        <br />- Crescimento, no mínimo, 50 pontos em 10 anos no PISA (o mais
        importante exame internacional de avaliação do ensino médio):
        <br />- Direcionamento do orçamento público da educação prioritariamente
        para a Educação Básica (Pré-Escola, Ensino Fundamental e Ensino Médio) e
        melhora da gestão dos recursos financeiros aportados.
        <br />- Garantia do financiamento adequado da educação básica, por meio
        de políticas que assegurem a vinculação constitucional de recursos para
        manutenção e desenvolvimento do ensino (MDE) e a repartição justa dos
        recursos da contribuição social do salário-educação entre os entes
        federados.
        <br />- Aperfeiçoamento do regime de colaboração entre os diferentes
        entes federativos na construção e implantação das políticas públicas da
        Educação Básica.
        <br />- Avanço na construção da autonomia pedagógica das escolas,
        prevista na Lei de Diretrizes Básicas (LDB), com apoio e supervisão das
        Secretarias e responsabilização pelos resultados educacionais.
        <br />- Aperfeiçoamento do regime de colaboração na construção e
        implantação das políticas de educação.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Aprimorar a Gestão das redes de ensino e das unidades escolares:
        </Bold>
        <br />- Qualificação do grupo técnico das secretarias de Educação,
        eliminando a interferência partidária na indicação de quadros pouco
        qualificados.
        <br />- Promoção de formação específica para líderes educacionais com
        foco em gestão e resultados pactuados.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Assegurar a adequada trajetória escolar dos educandos, </Bold>sem
        abandono e evasão e com progressão entre os anos letivos, com devido
        processo de acompanhamento e ações práticas cotidianas, de forma a
        concluir o Ensino Fundamental e o Médio na idade certa.
        <br />- Expansão do ensino em tempo integral com tutoria e projeto de
        vida e carreira.
        <br />- Inclusão do conteúdo associado ao mercado de trabalho
        <br />- Ações de busca ativa escolar como: fortalecimento de vínculos
        com os estudantes e comunidade escolar com estímulo socioemocionais à
        escola; pesquisas constantes com famílias e estudantes para mapear
        qualitativamente e atuar por prevenção; e sistematização e atualização
        dos dados dos alunos e responsáveis.
        <br />- Criação de programas de incentivos financeiros para os alunos da
        última etapa da Educação Básica
        <br />- Promoção do debate da postergação do horário de entrada na
        escola, principalmente no Ensino Médio, embasado em dados e evidências,
        para melhorar o rendimento escolar e reduzir a evasão dos jovens.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Inovar no Currículo para criação de habilidades do futuro, </Bold>
        com uso intensivo de tecnologias, da robótica, da automação e da
        inteligência artificial, associadas a um viés humanistico atrelado ao
        desafio de construir uma cidadania democrática e inclusiva.
        <br />- Garantia de uma escola inovadora, acolhedora e prazerosa ao
        longo da jornada de estudos e formação.
        <br />- Promoção do conhecimento, da formação da cidadania, do debate de
        ideias e da pluralidade de opiniões
        <br />- Formação integral das crianças e jovens com desenvolvimento das
        habilidades socioemocionais.
        <br />- Promoção de inclusão para todos alunos na sala de aula: educação
        orientada ao respeito da diversidade, sem discriminação ou preconceito à
        pessoas com deficiência, negros, indígenas, mulheres, LGBTQIAPN,
        reforçando o que já está estabelecido em leis e evitando a evasão por
        preconceito no ambiente escolar.
        <br />- Implementação das recomendações de políticas de equidade
        étnico-racial para assegurar um ambiente receptivo em todo ciclo
        <br />- Efetivação e o monitoramento, em toda ciclo escolar, para
        cumprimento das legislações inerentes ao ensino de História e Cultura
        Africana e Afro-Brasileira e para a Educação das Relações
        Étnico-Raciais.
        <br />- Inclusão do esporte e cultura nas rotinas escolares para
        promover maior qualidade de vida, engajamento cívico e empoderamento
        pessoal
        <br />- Garantia de atividades multidisciplinares para fomento do
        empreendedorismo.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Assegurar aprendizagem adequada e o desenvolvimento integral dos
          alunos em todo ciclo de ensino ao longo da vida
        </Bold>
        <br />- Garantia da qualidade técnica do processo de ensino e
        aprendizagem, oferecendo formação continuada aos docentes, com o
        necessário alinhamento às demandas dos docentes, ao material
        didático-pedagógico e ao sistema nacional de avaliação externa do
        desempenho escolar
        <br />- Garantia do acesso à escola das crianças e jovens na idade
        correspondente ao ensino obrigatório, assim como sua permanência e
        sucesso na aprendizagem.
        <br />- Possibilidade de recuperação de aprendizagem em qualquer momento
        do ciclo (como é o caso do EJA - Educação de Jovens e Adultos)
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Propor uma definição de diretrizes nacionais para as carreiras do
          magistério público da Educação Básica{" "}
        </Bold>
        reformulação profunda do modelo atual a fim de oferecer aos professores
        salários compatíveis com outros profissionais de mesmo nível de formação
        e um plano de desenvolvimento profissional, baseado em seu desempenho
        nas avaliações educacionais externas:
        <br />- Aperfeiçoamento da formação inicial do magistério;
        <br />- Melhora dos níveis de remuneração;
        <br />- Aprimoramento dos concursos públicos e da avaliação no estágio
        probatório;
        <br />- Reformulação completa das carreiras docentes;
        <br />- Garantia da formação continuada ao longo da vida funcional;
        <br />- Garantia de jornadas de trabalho com horas para planejamento e
        reuniões pedagógicas.
        <br />- Apoio aos estados, Distritos Federal e municípios na
        implementação de políticas de formação continuada dos profissionais de
        magistério articuladas com a implementação dos novos currículos da
        Educação Básica alinhados à BNCC.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Utilizar tecnologias educacionais, </Bold>desenvolver o ensino
        híbrido, nos segmentos que forem adequados, e a expansão da educação
        integral como oportunidades de avanço para a educação brasileira e
        melhoria da aprendizagem
        <br />- Aumento da infraestrutura de conectividade das unidades
        escolares e comunidades locais
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Estimular o ensino superior, técnico e profissionalizante a buscar
          novas fontes de financiamento,{" "}
        </Bold>
        considerando a possibilidade de cobrança de mensalidades de alunos de
        alta renda e estabelecendo a obrigatoriedade de trabalho no serviço
        público como contrapartida
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Fortalecer o regime de colaboração entre os entes federados e a gestão
          da educação,{" "}
        </Bold>
        por meio de políticas que promovam a cooperação entre as diferentes
        esferas de governo e a participação da sociedade civil (entre eles
        professores e universidades públicas e privadas) na definição das
        políticas educacionais
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        #primeirainfânciaemprimeirolugar #focoensinobásico #educaçãocomqualidade
        #equidadedeensino #focoemaprendizagem #gestãorededeensino
        #combaterevasão #currículoparafuturo #desenvolvimentointegraldosalunos
        #tecnologiaseducacionais #professoresvalorizados
        #estimuloensinosuperioreprofissionalizante
        #recursosalternativosensinosuperior #colaboraçãoentesfederativos
      </SubTitle> */}
    </>
  );
}
