import React from "react";

import styled from "styled-components";

const Title = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
`;

const SubTitle = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  margin-bottom: 16px;
`;

// const Bold = styled.span`
//   font-weight: 700;
// `;

export default function Two({ isMobile }) {
  return (
    <>
      <Title style={{ fontSize: isMobile ? 18 : 24 }}>Tucanês</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        Economia inovadora e sustentável, orientada para longo prazo e visão
        transversal de políticas públicas. Estabilidade é a chave para
        crescimento e competitividade. Apoiar grandes reformas, privatizar e
        investir onde necessário, além de gerir o país com responsabilidade
        fiscal, para gerar empregos e combater a inflação. Economia é meio para
        diminuir as desigualdades de maneira estrutural.
      </SubTitle>
      {/* <Title style={{ fontSize: isMobile ? 18 : 24 }}>Aprofundamento</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Construir uma política econômica inovadora, conectada com a economia
          mundial,
        </Bold>
        com visão compartilhada de país, a partir de planejamentos orientados a
        longo prazo e capaz de resistir às transições governamentais e crises
        sistêmicas: - Pautada em maior digitalização, regionalização produtiva e
        investimentos sociais
        <br />- Pautada na sustentabilidade: a economia verde deve ser
        transversal a qualquer política pública, garantindo geração de renda e
        fontes de investimentos internacional
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Promover, modernizar e dinamizar a economia por meio de concessões,
          privatizações e parcerias público-privadas.
        </Bold>
        <br />- Estes processos de modernização do Estado precisam ser
        acelerados, com prioridade para aquelas companhias que dão prejuízos aos
        cofres públicos, não tem valor estratégico ao país e tem baixa entrega
        de qualidade à população.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Reestruturar as finanças públicas dos governos, </Bold>
        para poder crescer e cuidar de maneira responsável do povo brasileiro:
        <br />- Equilíbrio fiscal é o passo número um para um Estado funcional
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Reestruturar as finanças públicas dos governos, </Bold>
        para poder crescer e cuidar de maneira responsável do povo brasileiro:
        <br />- Equilíbrio fiscal é o passo número um para um Estado funcional
        <br />- Maior coordenação entre governos e poderes, que converse com a
        reconstrução da governança pública no Brasil
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Preservar o poder de compra das pessoas, </Bold>
        ao priorizar o cumprimento do tripé macroeconômico: a partir do combate
        à inflação, com metas realistas e respeitadas, responsabilidade fiscal e
        câmbio flutuante; garantindo a independência do Banco Central.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Combater o desemprego e promover igualdade de oportunidades.
        </Bold>
        <br />
        - Trabalhadores precisam receber formação e treinamento, enquanto
        recebem o seguro-desemprego, para assegurar sua competitividade no
        mercado e aumento de produtividade.
        <br />
        - Compatibilização de qualificações e habilidades para as indústrias e
        segmentos produtivos para aumento da produtividade em comparação global
        e melhoria das condições de trabalho.
        <br />- Socialização dos riscos de desenvolvimento de capital humano
        para atividades de maior valor agregado para diminuir a lacuna econômica
        (economia de "catch-up"), aumentando o retorno social e o investimento
        individualizado, promovendo políticas de TIC (Tecnologia de Informação e
        Comunicação).
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Apoiar reformas estruturantes </Bold>
        que promovam redução de desigualdades e gerem desenvolvimento econômico
        e social.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Trabalhar pela inserção do país nas cadeias globais, </Bold>
        com melhoria do ambiente regulatório e redução substancial do
        custo-país, assim como pela criação de um ambiente de negócios mais
        propício à competição, mais dinâmico para as empresas e mais favorável
        para quem trabalha e produz.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Fomentar setores nos quais o país pode se destacar como:</Bold>
        <br />
        com melhoria do ambiente regulatório e redução substancial do
        custo-país, assim como pela criação de um ambiente de negócios mais
        propício à competição, mais dinâmico para as empresas e mais favorável
        para quem trabalha e produz.
        <br />
        <br />- <Bold>Economia criativa e cultura:</Bold> Democratização da
        cultura como estratégia de influência mundial ("Soft Power"), com
        incentivos públicos e privados de financiamento à cultura brasileira
        como produto de exportação, com aumento da competitividade das
        produções, influência política e econômica regional, incremento de
        empresas da cadeia do setor, e expansão do segmento se fazendo uso de
        tecnologias.
        <br />
        <br />- <Bold>Turismo:</Bold> atendimento das demandas atuais da
        experiência dos turistas domésticos e estrangeiros, com variedade de
        produtos, serviços, estrutura de turismo, <br />
        - Melhoria do capital humano considerando segmentações e suas condições
        climáticas para ampliação do potencial de desenvolvimento econômico do
        setor.
        <br />- Qualificação da mão de obra e profissionalização dos
        trabalhadores que atuam no setor: o Brasil precisa de mais turismólogos
        <br />- Parceria com Sistema S para financiamento compartilhado de
        programa de capacitação do setor.
        <br />- Incentivo e apoio ao turismo de eventos e negócios, vetores
        importantes para o desenvolvimento do turismo e contribuição para a
        geração de emprego, renda e gastos locais
        <br />- Turismo sustentável: impulsionamento de programa nacional de
        concessões que assegurem a coexistência fraternal entre preservação
        ambiental e fruição turística.
        <br />- Modernização e investimento focalizado na Embratur: proposta de
        transferir dotações oriundas das taxas de embarque internacional
        diretamente para a finalidade de promoção do turismo brasileiro no
        exterior.
        <br />
        <br />- <Bold>Empreendedorismo:</Bold> fomento de políticas que
        objetivam oportunidade e inovação pelo empreendedorismo. <br />
        - Melhoria do capital humano considerando segmentações e suas condições
        climáticas para ampliação do potencial de desenvolvimento econômico do
        setor.
        <br />- Incremento de investimentos de políticas distributivas, promoção
        de cultura e educação empreendedora, desenvolvimento de indústria de
        incubadoras e venture capital, programas de promoção de PD&I e programas
        de fomento à internacionalização.
        <br />- Regulamentação simplificada, com redução de custos de
        burocracias, redução dos tempos médios de licenciamento, execução de
        contratos e aperfeiçoamento e unificação de tecnologias de registro e
        pagamentos (e-gov).
        <br />- Criação e melhoria de políticas com foco no aumento de volume de
        negócios, crescimento e alavancagem da sustentabilidade das empresas
        aumentando o ciclo de vida das empresas.
        <br />- Ampliação da política de microfinanças produtivas, melhoria das
        tecnologias financeiras inclusivas, monitoramento de crédito produtivo e
        educação financeira.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Rever a cadeia produtiva da industrialização </Bold>
        brasileira para inserção no mercado global de maneira competitiva:
        <br />- Enfrentamento dos desafios de preenchimento de lacuna econômica
        para atividades de maior valor agregado que promovam retorno social,
        público e privado promovendo crescimento sustentado.
        <br />- Fomento de políticas com promoção de assistência financeira e
        técnica para a revitalização de suas indústrias.
        <br />- Revisionismo das políticas de incentivos fiscais para modelo
        progressivo com base em metas de geração de emprego, pesquisa e
        desenvolvimento e uso de novas tecnologias e tecnologias verdes e
        inovação.
        <br />- Políticas de Incentivos "Made in Brazil" para substituição de
        importações para produtos com maior valor agregado, promovendo
        reindustrialização nacional.
        <br />- Adoção da "Estratégia de Alta Tecnologia", para melhoria dos
        centros de tecnologia e inovação com incremento de orçamento público com
        matching de parcerias privadas e da universidades.
        <br />- Aumento de investimento de educação e infraestrutura focalizada
        nas novas indústrias em setores estratégicos.
        <br />- Promoção, diversificação econômica e clusterização industrial
        com atração de investimento, facilitando a entrada e manutenção do
        capital estrangeiro no país.
        <br />- Foco no desenvolvimento de P&D e na formação de capital humano
        especialista em áreas com alto valor agregado à economia.
        <br />- Modernização do país pela reforma para uma neo-indústria com
        abertura global com parcerias e ampliação de cadeias produtivas.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Introduzir o Seguro-Destrabalho, </Bold>
        novo seguro social que busca construir uma nova forma de proteção de
        todos os trabalhadores - não apenas aqueles com emprego formal.
        <br />- Condicionamento desse novo benefício à formação, treinamento e
        requalificação desses trabalhadores para se tornarem melhores
        microempreendedores: para produzir mais, trabalhar em serviços públicos
        e comunitários e para recuperar e manter o consumo.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        #economiamoderna #estadonecessário #responsabilidadefiscal
        #combateainflação #reformasestruturantes #segurotrabalho #emprego
        #produtividade #empreendedorismo #reformasestruturantes #cadeiaprodutiva
        #industrialização
      </SubTitle> */}
    </>
  );
}
