export default function Helper() {
  const isMobile = function () {
    return window.innerWidth <= 1024;
  };
  const screenWidth = function () {
    return window.innerWidth;
  };
  const screenHeight = function () {
    return window.innerHeight;
  };
  return {
    isMobile,
    screenWidth,
    screenHeight,
  };
}
