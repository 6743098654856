import React from "react";

import styled from "styled-components";

const Title = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
`;

const SubTitle = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  margin-bottom: 16px;
`;

// const Bold = styled.span`
//   font-weight: 700;
// `;

export default function Nine({ isMobile }) {
  return (
    <>
      <Title style={{ fontSize: isMobile ? 18 : 24 }}>Tucanês</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        Mais acesso, eficiência, qualidade e rapidez na saúde. É preciso inovar,
        investir em tecnologia e rever o pacto federativo, para equalizar a
        responsabilização e recursos de cada ente. A saúde contempla uma visão
        integrada e humanizada do indivíduo.
      </SubTitle>
      {/* <Title style={{ fontSize: isMobile ? 18 : 24 }}>Aprofundamento</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Pautar as políticas Priorizar o princípio da equidade</Bold>,
        parte dos atos estruturadores do SUS e{" "}
        <Bold>promover atenção de qualidade</Bold> que contemple as dimensões
        biológica, psicológica e sociais de um indivíduo, com visão integrada
        dos aspectos de promoção, prevenção e recuperação da saúde
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Reequilibrar o pacto federativo, </Bold>para assegurar efetividade
        das políticas públicas de saúde, e superar o subfinanciamento dos
        municípios
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Focar no desenvolvimento de ações de incidência para o fortalecimento
          da atenção primária à saúde,
        </Bold>{" "}
        com práticas mais coletivas, contínuas e interdisciplinares, como o
        autocuidado apoiado e o cuidado compartilhado
        <br />- Promoção da mudança na forma de remuneração de médicos e equipes
        multiprofissionais, saindo do pagamento por procedimento ou por salários
        fixos para o pagamento por performance ou desempenho, focalizado na
        prevenção baseada em evidências.
        <br />- Implantação de teleassistência para garantir o apoio diagnóstico
        aos profissionais de saúde da família, teleconsultas e
        telemonitoramento.
        <br />- Promoção de mudanças na qualidade e qualificação dos gestores do
        SUS, escolhidos por prefeitos, criando critérios mínimos de formação
        para assumirem os cargos.
        <br />- Fortalecimento da Vigilância em Saúde integrando as ações de
        vigilância, prevenção, proteção e promoção à saúde, todas coordenadas
        com demais ações e serviços desenvolvidos e ofertados no SUS, com vistas
        a garantir a integralidade da atenção à saúde da população.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Promover a saúde mental</Bold> e sua aplicação no SUS, inclusive
        com o uso de dados e indicadores, para que haja monitoramento histórico
        e acompanhamento terapêutico completo dos usuários
        <br />- Qualificação do debate e campanhas de conscientização pública.
        <br />- Parcerias junto a instituições não governamentais para
        viabilizar programas de saúde mental em larga escala.
        <br />- Geração de informação e incentivo do monitoramento e
        fiscalização das políticas públicas de saúde mental em articulação
        ministerial
        <br />- Aprimoramento da Rede de Atenção Psicossocial (RAPS).
        <br />- Promoção de políticas de treinamento e de valorização de
        profissionais de saúde mental.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Aumentar capacidade de investimentos público e privado para pesquisa,
          inovação, tecnologia e inteligência artificial
        </Bold>{" "}
        para garantia de qualidade e eficiência em larga escala.
        <br />- Criação de Centros de Excelência que promovam aconselhamento
        para profissionais da saúde para ampliar uso e domínio de tecnologias a
        favor de pacientes e famílias.
        <br />- Promoção de políticas governamentais que estimulem uma forte
        estrutura de ambiente de inovação em saúde com foco em compras públicas,
        fomentando eficiência, excelência e desenvolvimento econômico do setor.
        <br />- Realização de campanhas e treinamentos para uso correto da
        tecnologia com foco melhoria da eficiência e gestão como a integração de
        registros médicos eletrônicos, uso de tecnologia analytics e machine
        learning na atuação de prevenção de doenças, gerenciamento remoto
        domiciliar e telemedicina, prevenção de erros de medicação, uso de
        plataformas e aplicativos e para diminuição de custos e redução de tempo
        de atendimento e procedimentos.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Revisar princípios de integração e regionalização do SUS,</Bold>{" "}
        para aprimorar a governança do sistema:
        <br />- Revisão dos modelos de relacionamentos federativos setoriais,
        para aliviar a sobrecarga sobre os municípios polos, melhorando o acesso
        em municípios satélites
        <br />- Discussão quanto ao rearranjo nas redes de atenção com recorte
        regional precisa observar a necessidade de uma estrutura de gerência
        supramunicipal e interestadual.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Fortalecer o Sistema Único de Saúde</Bold> (SUS), por meio da/do:
        <br />- Busca de fontes adicionais e perenes de financiamento; combate
        aos desperdícios e a corrupção e fim dos subsídios que subtraem
        receitas, inclusive aqueles ligados ao Imposto de Renda de Pessoa Física
        (IRPF)
        <br />- Ampliação do acesso e da cobertura.
        <br />- Investimento em infraestrutura de qualidade com espraiamento da
        rede de cobertura.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Retomar debate e propor uma Reforma Psiquiátrica</Bold>
        <br />- Criação de estrutura para centralizar a coordenação da Política
        Nacional de Saúde Mental.
        <br />- Aumento significativo do orçamento público destinado à saúde
        mental.
        <br />- Viabilização de Conferências Nacionais e Encontros Estaduais de
        Saúde Mental.
        <br />- Implementação do Conselho Gestor nos serviços de saúde mental,
        com representação de todos os interessados.
        <br />- Priorização de atendimento ao público das crianças e
        adolescentes.
        <br />- Recriação do Programa Federal de Reinserção Social para promover
        ações coordenadas de reabilitação da pessoa com transtorno mental e
        usuários de droga
        <br />- Fechamento da porta de entrada para hospitais psiquiátricos com
        característica asilar de forma gradual e progressiva.
        <br />- Garantia de equipes multidisciplinares nas Audiências de
        Custódia, em articulação com o Ministério da Justiça e Segurança
        Pública.
        <br />- Estímulo à autonomia das pessoas com transtorno mental por meio
        de ações que visem a implementação de auxílio financeiro, oferta de
        cursos profissionalizantes e geração de emprego e renda.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Regulamentar a viabilização, produção, prescrição e acesso gratuito e
          universal pelo Sistema Único de Saúde (SUS) a uma ampla gama de formas
          farmacêuticas da cannabis e derivados,
        </Bold>{" "}
        sempre respaldadas por evidências sólidas de segurança e eficácia
        terapêutica
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Garantir o tratamento especializado para grupo minorizados no SUS,
        </Bold>{" "}
        para que o atendimento humanizado seja capaz de cuidar das
        especificidades de sexo, gênero, raça, idade e orientação sexual.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Criar de solução multidisciplinar liderada pela saúde, para que a
          população adicta alcance reintegração social,
        </Bold>{" "}
        utilizando métodos como consumo supervisionado e promoção de
        oportunidades de empregos e renda
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Reverter a baixa cobertura vacinal do país</Bold> a partir da
        articulação e fortalecimento de ações estruturais, operacionais e
        interinstitucionais.
        <br />- Campanha de divulgação das informações sobre a importância da
        prevenção de doenças a partir da vacinação da população.
        <br />- Investimento em pesquisa e tecnologia para o desenvolvimento
        acelerado de vacinas e prevenção de novas epidemias
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        #equidadenoSUS #SUS #saúdedequalidade #saúdemental #saúdementalcomdados
        #reformapsiquiátrica #pactofederativo #tecnologianasaúde #telemedicina
        #regionalizaçãodoSUS #vacina #tratamentohumanizado
      </SubTitle> */}
    </>
  );
}
