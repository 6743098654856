import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";
import Share from "./share";

const Element = styled.div`
  background: #e3ffd3;
  border-radius: 12px;
  background: #e3ffd3;
  width: 100%;
`;

const Info = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;

const InfoImage = styled.img``;

const InfoText = styled.div``;

const InfoLabel = styled.div`
  color: #0f2bc5;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;

const InfoTitle = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

const InfoSubtitle = styled.div`
  color: #565656;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 18px;
`;

const Player = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Play = styled.div`
  width: 54px;
  height: 54px;
  background: url(/img/play.svg) center center no-repeat;
  background-size: contain;
  cursor: pointer;

  &:hover {
    background: url(/img/play_hover.svg) center center no-repeat;
    background-size: contain;
  }
`;

const Pause = styled.div`
  width: 54px;
  height: 54px;
  background: url(/img/pause.svg) center center no-repeat;
  background-size: contain;
  cursor: pointer;

  &:hover {
    background: url(/img/pause_hover.svg) center center no-repeat;
    background-size: contain;
  }
`;

const Track = styled.div`
  margin-left: 8px;
  width: 100%;
  height: 54px;
  position: relative;
`;

const Bars = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 54px;
`;

const Bar = styled.div`
  width: 4px;
  height: 30px;
  background: #b6d1ae;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background: #b6d1ae;
  }
`;

const Circle = styled.div`
  position: absolute;
  left: 0%;
  margin-left: -5px;
  top: 21px;
  border-radius: 45px;
  background: #0f2bc5;
  width: 12px;
  height: 12px;
  /* transition: 0.5s; */
`;

const CurrentTime = styled.div`
  color: #a4a4a4;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  position: absolute;
  left: 0px;
  bottom: -24px;
`;

const TotalTime = styled.div`
  color: #a4a4a4;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  position: absolute;
  right: 0px;
  bottom: -24px;
`;

export default function Audio({
  number,
  isMobile,
  audioImage,
  title,
  audioTitle,
  audioSubtitle,
  audioFile,
}) {
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const max = isMobile ? 35 : 70;

  const [bars, setBars] = useState([]);
  const [height, setHeight] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const audioRef = useRef();

  const getTimeFromSeconds = (seconds) => {
    try {
      if (seconds && seconds > 0) {
        return new Date(seconds * 1000).toISOString().slice(14, 19);
      }
    } catch (error) {
      console.log(error);
    }
    return "00:00";
  };

  const updateHeight = () => {
    let _height = [];
    for (let index = 0; index < max; index++) {
      _height.push(Math.random() * 54);
    }
    setHeight(_height);
  };

  const updateInterface = () => {
    let _bars = [];
    for (let index = 0; index < max; index++) {
      if (index < (max * progress) / 100) {
        _bars.push(1);
      } else {
        _bars.push(0);
      }
    }
    setBars(_bars);
  };

  const onLoadedMetadata = () => {
    setLoaded(true);
  };

  useEffect(() => {
    updateInterface();
    updateHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const play = () => {
    setPlaying(true);
    audioRef.current.play();
  };

  const pause = () => {
    setPlaying(false);
    audioRef.current.pause();
  };

  useEffect(() => {
    updateInterface();
    if (progress === 100) {
      setProgress(0);
      pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const playAnimationRef = useRef();

  // eslint-disable-next-line
  const repeat = () => {
    setProgress(
      parseInt((audioRef.current.currentTime / audioRef.current.duration) * 100)
    );
    playAnimationRef.current = requestAnimationFrame(repeat);
  };

  useEffect(() => {
    if (playing) {
      playAnimationRef.current = requestAnimationFrame(repeat);
    } else {
      cancelAnimationFrame(playAnimationRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing, audioRef, repeat]);

  return (
    <Element style={{ padding: isMobile ? 16 : 32 }}>
      <Info style={{ display: isMobile ? "block" : "flex" }}>
        <InfoImage
          src={audioImage}
          style={{
            width: isMobile ? "100%" : 238,
            marginBottom: isMobile ? 16 : 0,
          }}
        />
        <InfoText style={{ paddingLeft: isMobile ? 0 : 24 }}>
          <InfoLabel>Pra entender melhor</InfoLabel>
          <InfoTitle>{audioTitle}</InfoTitle>
          <InfoSubtitle>{audioSubtitle}</InfoSubtitle>
          <Share
            center={false}
            url={`https://brasil2045.com.br/?p=objetivo_${number}`}
            text={`${title}: venha escutar o que o PSDB tem a dizer sobre o tema`}
          />
        </InfoText>
      </Info>
      <Player>
        {playing ? <Pause onClick={pause} /> : <Play onClick={play} />}
        <Track>
          <Circle
            style={{
              left: `${progress}%`,
            }}
          />
          <Bars>
            {bars.map((bar, index) => {
              return (
                <Bar
                  style={{
                    height: height[index],
                    background: bar ? "#A4A4A4" : "#B6D1AE",
                  }}
                  onClick={() => {
                    const _progress = index / max;
                    audioRef.current.currentTime =
                      audioRef.current.duration * _progress;
                    play();
                    setProgress(_progress * 100);
                  }}
                />
              );
            })}
          </Bars>
          {loaded && (
            <>
              <CurrentTime>
                {getTimeFromSeconds(audioRef.current.currentTime)}
              </CurrentTime>
              <TotalTime>
                {getTimeFromSeconds(audioRef.current.duration)}
              </TotalTime>
            </>
          )}
        </Track>
      </Player>
      <audio ref={audioRef} onLoadedMetadata={onLoadedMetadata}>
        <source src={audioFile} type="audio/mpeg" />
      </audio>
    </Element>
  );
}
