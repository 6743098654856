import React from "react";

import styled from "styled-components";

const Element = styled.div`
  /* margin-bottom: 16px; */
`;

const LogoHolder = styled.div`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 30px;
  width: 100%;
`;

const Logo = styled.img`
  width: 100px;
`;

const OpeningHolder = styled.div`
  text-align: center;
  width: 100%;
  padding: 0px 16px;
`;

const Opening = styled.img`
  width: 771px;
  max-width: 100%;
`;

const OpeningText = styled.div`
  padding: 0px 16px;
  text-align: center;
  font-style: normal;
  line-height: 130%;
  color: #565656;
  max-width: 1440px;
  margin: 0 auto;
`;

const Download = styled.a`
  color: rgb(27, 100, 243);
  margin-bottom: 54px;
  font-size: 24px;
  cursor: pointer;
  text-decoration: underline;
`;

export default function Intro({ width, height, isMobile }) {
  return (
    <Element style={{ paddingBottom: isMobile ? 56 : 80 }}>
      <LogoHolder>
        <Logo src="/img/logo.png" />
      </LogoHolder>
      <OpeningHolder>
        <Opening src="/img/abertura.png" />
      </OpeningHolder>
      <OpeningText
        style={{
          padding: isMobile ? "0 16px" : "0 197px",
          fontSize: isMobile ? 18 : 24,
        }}
      >
        É o DNA Tucano. É a base de uma discussão política, técnica e social
        ampla dos principais pontos que acreditamos e nos importamos. Sim, nós
        nos importamos. Nos importamos com cada um dos brasileiros e
        brasileiras. A intencionalidade do documento é dar um uso prático a ele,
        para que seja o lugar de encontro entre políticas públicas e a política
        brasileira. E assim reconectar o PSDB com o Brasil. <br />
        <br />
        <Download href="/dna_tucano.pdf" target="_blank">
          Acesse a Carta Síntese
        </Download>
      </OpeningText>
    </Element>
  );
}
