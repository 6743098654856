import React, { useState } from "react";

import styled from "styled-components";
import Storage from "../libs/storage";
import Api from "../libs/api";

const Element = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Block = styled.div`
  width: 42px;
  height: 42px;
  cursor: pointer;
`;

const Whatsapp = styled.a`
  display: block;
  width: 42px;
  height: 42px;
  background: url("/img/whatsapp.svg");
  background-position: center center;
  background-repeat: no-repeat;

  &:hover {
    background: url("/img/whatsapp_hover.svg");
  }
`;

const Twitter = styled.a`
  display: block;
  width: 42px;
  height: 42px;
  background: url("/img/twitter.svg");
  background-position: center center;
  background-repeat: no-repeat;

  &:hover {
    background: url("/img/twitter_hover.svg");
  }
`;

const Facebook = styled.a`
  display: block;
  width: 42px;
  height: 42px;
  background: url("/img/facebook.svg");
  background-position: center center;
  background-repeat: no-repeat;

  &:hover {
    background: url("/img/facebook_hover.svg");
  }
`;

const Like = styled.div`
  display: block;
  width: 42px;
  height: 42px;
  background: url("/img/like.svg");
  background-position: center center;
  background-repeat: no-repeat;

  &:hover {
    background: url("/img/like_hover.svg");
  }

  &.liked {
    background: url("/img/liked.svg");

    &:hover {
      background: url("/img/liked_hover.svg");
    }
  }
`;

export default function Share({ url, text, center, id }) {
  const storage = Storage();
  const api = Api();

  const [liked, setLiked] = useState(
    storage.getItem(`${btoa(id)}`) ? true : false
  );

  const like = async (e) => {
    e.preventDefault();
    setLiked(true);
    storage.saveItem(`${btoa(id)}`, true);
    const response = await api.like(id);
    console.log(response);
  };

  return (
    <Element
      style={{ margin: center ? "0 auto" : "", width: id ? 208 : 208 - 52 }}
    >
      <Block>
        <Whatsapp
          href={`https://api.whatsapp.com/send?&text=${text}%20${encodeURI(
            url
          )}`}
          target="_blank"
        />
      </Block>
      <Block>
        <Twitter
          href={`https://twitter.com/intent/tweet?text=${text}&url=${encodeURI(
            url
          )}`}
          target="_blank"
        />
      </Block>
      <Block>
        <Facebook
          href={`https://www.facebook.com/sharer/sharer.php?text=${text}&u=${encodeURI(
            url
          )}`}
          target="_blank"
        />
      </Block>
      {id && (
        <Block>
          <Like
            className={liked ? "liked" : ""}
            onClick={liked ? () => {} : like}
          />
        </Block>
      )}
    </Element>
  );
}
