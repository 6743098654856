import React from "react";

import styled from "styled-components";

const Title = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
`;

const SubTitle = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  margin-bottom: 16px;
`;

// const Bold = styled.span`
//   font-weight: 700;
// `;

export default function Ten({ isMobile }) {
  return (
    <>
      <Title style={{ fontSize: isMobile ? 18 : 24 }}>Tucanês</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        O Brasil deve ser protagonista no combate à insegurança alimentar. Uma
        potencial agro e ambiental, gerando desenvolvimento econômico
        sustentável e sustentado. Precisamos olhar para o setor no longo prazo e
        defender metas de para a atividade econômica no campo. Garantir a
        segurança jurídica, recursos internacionais, investimento em tecnologia,
        logística e infraestrutura. Combater as desigualdades do campo e
        garantir a competitividade do agronegócio no mundo.
      </SubTitle>
      {/* <Title style={{ fontSize: isMobile ? 18 : 24 }}>Aprofundamento</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Garantir a segurança jurídica do campo para os produtores, oferecendo
          proteção aos seus direitos de propriedade.
        </Bold>
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Criar plano de metas, orientado ao longo prazo, e política permanente
          para a agricultura,
        </Bold>{" "}
        que forneça diretrizes claras para os produtores e traga estabilidade e
        protagonismo ao setor.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Transformar o Plano Safra em programa permanente com metas
          estratégicas e adequações periódicas,
        </Bold>{" "}
        respeitando as peculiaridades regionais
        <br />- Estabelecimento de um programa permanente de crédito rural, com
        diretrizes claras e metas estratégicas a serem alcançadas.
        <br />- Adoção de práticas sustentáveis e incentivos à produção agrícola
        de baixo impacto ambiental.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Assegurar competitividade com demais países,</Bold> priorizando a
        abertura comercial
        <br />- Promoção do acesso a crédito com juros acessíveis e prazos
        adequados ao produtor no Brasil e facilitar a contratação de empréstimos
        no exterior, garantindo condições de concorrência com a produção
        internacional.
        <br />- Investimento em logística para reduzir os custos de transporte e
        escoamento da produção agrícola.
        <br />- Investimento em inovação tecnológica
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Corrigir as desigualdades no setor rural brasileiro, implementando
          políticas que promovam a inclusão social no campo
        </Bold>
        , a partir da ampliação do conhecimento de mercado, qualificação,
        assistência técnica e gerencial para pequenos produtores rurais e
        comunidades tradicionais, garantindo que deixarão de ser dependentes de
        programas sociais para contribuintes do desenvolvimento do País.
        <br />- Promoção de políticas públicas que incentivem a produção e
        evitem o êxodo rural, além de transformar os produtores rurais em
        empreendedores.
        <br />- Estabelecimento de parcerias entre os setores público e privado
        para impulsionar o desenvolvimento do setor agropecuário
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Fortalecer e modernizar a Embrapa, </Bold>alinhado aos interesses
        do agronegócio sustentável
        <br />- Garantir desenvolvimento de tecnologias e práticas agrícolas
        inovadoras e assegurar ganho de produtividade
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Ampliar a atuação de fundos designados para combate às mudanças
          climáticas,
        </Bold>{" "}
        auxiliando na resolução de gargalos do seguro rural
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        #planodemetasagro #políticapermanenteagricultura #segurançajurídica
        #inclusãosocialnocampo #créditoeempréstimosacessíveis
        #competitividadeagro #aberturacomercial #inovaçãorecnológica
        #investimentoemlogística #incentivoaprodução
        #produtoresruraisempreendedores
      </SubTitle> */}
    </>
  );
}
