import React from "react";

import styled from "styled-components";

const Element = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const Left = styled.div`
  width: 45%;
  color: #565656;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 67.5px */
`;

const Right = styled.div`
  width: 45%;
  font-weight: 700;
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-top: 32px;
  border-top: 1px solid #b9b9b9;
`;

const Link = styled.a`
  color: #565656;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  text-decoration-line: underline;

  &:hover {
    color: #0f2bc5;
  }
`;

export default function References({ width, height, isMobile }) {
  return (
    <Element
      style={{
        display: isMobile ? "block" : "flex",
        padding: isMobile ? "0px 16px 56px" : "0px 54px 80px",
      }}
    >
      <Left
        style={{
          marginBottom: isMobile ? 32 : 0,
          fontSize: isMobile ? 24 : 54,
          width: isMobile ? "100%" : "45%",
        }}
      >
        Referências <br />
        Bibliográficas
      </Left>
      <Right
        style={{
          fontSize: isMobile ? 18 : 24,
          width: isMobile ? "100%" : "55%",
        }}
      >
        <Link
          style={{ fontSize: isMobile ? 18 : 24 }}
          href="https://www.psdb.org.br/wp-content/uploads/2020/08/Livro-Congresso-PSDB.pdf"
          target="_blank"
        >
          Acima de tudo, democracia ↗
        </Link>
        <br />
        <Link
          style={{ fontSize: isMobile ? 18 : 24 }}
          href="https://docs.google.com/document/d/14cK33W_fJZ8Kr-WoKwtNB6C3Jd4i5VPt/edit"
          target="_blank"
        >
          Diversidade Tucana Nacional: Só quem tem história pode construir o
          futuro ↗
        </Link>
        <br />
        <Link
          style={{ fontSize: isMobile ? 18 : 24 }}
          href="https://www.psdb.org.br/wp-content/uploads/2022/07/tse-estatuto-psdb-de-09.12-2017-deferido-em-21-8-2018-substiuicao-em-10.2.2020-1.pdf"
          target="_blank"
        >
          Estatuto do Partido da Social Democracia Brasileira ↗
        </Link>
        <br />
        <Link
          style={{ fontSize: isMobile ? 18 : 24 }}
          href="https://itv.org.br/wp-content/uploads/2020/07/O_BRASIL_QUE_QUEREMOS.pdf"
          target="_blank"
        >
          Gente em primeiro lugar: o Brasil que queremos ↗
        </Link>
        <br />
        <Link
          style={{ fontSize: isMobile ? 18 : 24 }}
          href="https://www.psdb.org.br/wp-content/uploads/2020/12/BRASIL-P%C3%93S-PANDEMIA-FINAL.pdf"
          target="_blank"
        >
          O Brasil Pós-Pandemia: uma visão de futuro ↗
        </Link>
        <br />
        <Link
          style={{ fontSize: isMobile ? 18 : 24 }}
          href="https://tucano.org.br/wp-content/uploads/2013/12/Cartilha-PSDB-Bras%C3%ADlia.pdf"
          target="_blank"
        >
          Para mudar de verdade o Brasil: Confiança, cidadania e prosperidade ↗
        </Link>
        <br />
        <Link
          style={{ fontSize: isMobile ? 18 : 24 }}
          href="https://tucano.org.br/wp-content/uploads/2013/12/Cartilha-PSDB-Bras%C3%ADlia.pdf"
          target="_blank"
        >
          Bandeiras eleitorais: A agenda das mulheres tucanas para uma sociedade
          mais justa, humana e sustentável ↗
        </Link>
        <br />
        <Link
          style={{ fontSize: isMobile ? 18 : 24 }}
          href="https://psdb-mulher.org.br/ebook-O-Brasil-que-queremos/"
          target="_blank"
        >
          O Brasil que queremos na visão das Mulheres tucanas ↗
        </Link>
      </Right>
    </Element>
  );
}
