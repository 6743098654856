import React from "react";

import styled from "styled-components";

const Title = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
`;

const SubTitle = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  margin-bottom: 16px;
`;

// const Bold = styled.span`
//   font-weight: 700;
// `;

export default function Eleven({ isMobile }) {
  return (
    <>
      <Title style={{ fontSize: isMobile ? 18 : 24 }}>Tucanês</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        Investimos em infraestrutura de qualidade (da social à econômica), com
        ética e transparência, com desestatização previamente decidida. Vamos
        alcançar, no mínimo, o nível médio de infraestrutura dos demais países.
      </SubTitle>
      {/* <Title style={{ fontSize: isMobile ? 18 : 24 }}>Aprofundamento</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Alinhar o Brasil com a tendência global de investimentos em
          infraestrutura verde, incentivando a sustentabilidade e a
          responsabilidade corporativa,{" "}
        </Bold>
        com metas de curto prazo e incentivos adequados, inclusive no tocante a
        carga tributária e melhores condições de financiamento.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Aumentar o nível de investimento em infraestrutura</Bold> para se
        igualar, no mínimo, à média global, utilizando-se de parcerias
        público-privada para agilizar a implementação e desinchar o Estado.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Estabelecer políticas urbanas nacionais que promovam o desenvolvimento
          urbano sustentável,{" "}
        </Bold>
        saúde, segurança e qualidade de vida nas cidades.
        <br />- Garantia da eficiência e da redução de obstáculos nos serviços
        de infraestrutura de transporte, com novas tecnologias de conectividade
        e compartilhamento, que contribuam para um ambiente mais limpo, seguro e
        habitável.
        <br />- Garantia de habitação e moradia social para todos
        <br />- Promoção urgente da universalização do saneamento básico
        <br />- Garantia do acesso à energia elétrica nas áreas urbanas e
        rurais. Assim como o acesso à comunicação, via rádio, telefonia e
        internet
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Avaliar parcerias públicas-privadas, concessões e privatizações no
          desenvolvimento de equipamentos
        </Bold>{" "}
        voltados à saúde, educação, habitação e segurança, estabelecendo
        condições para uma gestão privada, caso necessário, de hospitais, postos
        de saúde, escolas, creches, novos bairros planejados, cidades
        inteligentes, presídios e centros de reabilitação.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Aprimorar a matriz energética brasileira</Bold>
        considerando subsídios para redução da pobreza e desigualdades,
        alinhados à inovação tecnológica, economia verde e descarbonização.
        <br />- Compromisso de erradicação de fontes de geração a base de óleo
        combustível e carvão com incentivo a fontes mais limpas, como gás
        natural para transição da matriz energética.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Priorizar políticas de infraestrutura que promovam o desenvolvimento
          pleno das regiões Norte e Nordeste.
        </Bold>
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Melhorar a qualidade da infraestrutura ao longo do ciclo de vida dos
          projetos,
        </Bold>{" "}
        tanto no setor público quanto no privado, garantindo melhor qualidade
        dos serviços à população. Pautar as políticas
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Requalificar os investimentos em infraestrutura de transporte,{" "}
        </Bold>
        incluindo planejamento atualizado, modelagem de concessões,
        gerenciamento de obras, manutenção de ativos, monitoramento e avaliação
        de impacto.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Fortalecer as agências reguladoras,</Bold> promovendo
        transparência, capacitação e gestão eficiente.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Melhorar as condições para financiamento, </Bold>adotando
        mecanismos com foco em resultados (”performance-driven loan”) que
        apontem para a implantação de estruturas de "project finance", melhoria
        nos pacotes de seguros e garantias, inclusive de seguros de risco
        político e ampliando acesso a fontes em moeda estrangeira;
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Revisar o Plano Integrado de Longo Prazo da Infraestrutura</Bold>{" "}
        2021-2050 em consonância com as necessidades da população brasileira,
        como política de Estado e não de Governo.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        #aprimorarmatrizenergética #níveldeinvestimentoglobal
        #políticasurbanasdesenvolvimentosustentável
        #parceriasparadesenvolvimerequipamentos #melhorarqualidadeinfra
        #fortaleceragênciasreguladoras #estruturasdeprojectfinance
        #PlanoIntegradodeLongoPrazo #investimentoseminfraverde
        #aumentarinvestimentos #priorizarnorteenordeste
        #requalificarinvestimentostransporte #melhorarfinancimento
      </SubTitle> */}
    </>
  );
}
