import { Routes, Route } from "react-router-dom";
import React from "react";

import "./style/style.css";

// import Blank from "./pages/blank.js";
import Home from "./pages/home.js";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default App;
