import React from "react";

import styled from "styled-components";
import Geometry from "./geometry";
import Share from "./share";
import Value from "./value";

const Element = styled.div`
  background-color: #0f2bc5;
  /* padding: 40px 0px; */
`;

const Title = styled.div`
  color: #fff;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 16px;
`;

const Subtitle = styled.div`
  color: #fff;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 16px;
`;

const TweetHolder = styled.div`
  text-align: center;
  padding: 0px 16px;
  max-width: 1440px;
  margin: 0 auto;
`;

const TweetImg = styled.img`
  width: 778px;
  max-width: 100%;
`;

const ValuesTitle = styled.div`
  color: #fff;
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  padding: 0px 16px;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 24px;
`;

export default function Tweet({ width, height, isMobile }) {
  return (
    <Element id="tweet" style={{ padding: isMobile ? "56px 0px" : "80px 0px" }}>
      <Title
        style={{
          padding: isMobile ? "0 16px" : "0 197px",
          fontSize: isMobile ? 18 : 24,
        }}
      >
        A Agenda em um tuíte
      </Title>
      <Subtitle
        style={{
          padding: isMobile ? "0 16px" : "0 197px",
          fontSize: isMobile ? 24 : 54,
        }}
      >
        Um Brasil que oferece igualdade de{" "}
        <Geometry bold={true} type={1}>
          oportunidades
        </Geometry>{" "}
        para quem está chegando e que enfrenta e corrige as desigualdades para
        quem, infelizmente, foi deixado para trás. Um país mais{" "}
        <Geometry bold={true} type={1}>
          próspero
        </Geometry>{" "}
        e{" "}
        <Geometry bold={true} type={1}>
          produtivo
        </Geometry>
        , mais{" "}
        <Geometry bold={true} type={1}>
          justo
        </Geometry>{" "}
        com seus filhos, mais livre,{" "}
        <Geometry bold={true} type={1}>
          mais verde
        </Geometry>{" "}
        e mais{" "}
        <Geometry bold={true} type={1}>
          relevante
        </Geometry>{" "}
        no mundo.
      </Subtitle>
      <Share
        center={true}
        url={"https://brasil2045.com.br/?p=tweet"}
        text={"Venha conhecer a Agenda Tucana em um tuíte:"}
        id={"A Agenda em um tuíte"}
      />
      <TweetHolder>
        <TweetImg src="/img/tweet.png" />
      </TweetHolder>
      <ValuesTitle>Valores do PSDB</ValuesTitle>
      <Value
        image={"/img/dialogar.png"}
        title={"Dialogar é sempre melhor que polarizar"}
        text={
          "Dialogar é via de mão dupla: falar e escutar com empatia. Isso é diálogo construtivo. É preciso compromisso e respeito com as diferenças. O Brasil quer paz e inclusão. Quando a individualidade é respeitada e é possível dialogar, inclusive com o diferente, temos uma democracia saudável. Fazemos oposição aos extremos e somos responsáveis. Concordamos com o que é melhor para o povo brasileiro e discordamos de soluções populistas. Nascemos da social-democracia e somos hoje o centro  dinâmico que o Brasil precisa. Lutaremos até o fim."
        }
        isMobile={isMobile}
        inverted={false}
      />
      <Value
        image={"/img/suficiente.png"}
        title={"Bom ainda não é suficiente em um país com miséria"}
        text={
          "Inconformidade com a realidade brasileira é o que nos move. Ser bons gestores e representantes é o ponto de partida. A chegada é acabar com a miséria e desigualdade do país. Gestão é meio, não é fim. Em nossos governos, fazemos acontecer e o que nos move é o sonho de um Brasil melhor. Nossa responsabilidade é melhorar a vida do povo. Para cuidar das pessoas é preciso ter coragem para encarar os problemas de frente e focar energias para resolver e não só ganhar eleições. Fizemos muitas vezes e faremos novamente."
        }
        isMobile={isMobile}
        inverted={true}
      />
      <Value
        image={"/img/necessario.png"}
        title={"Estado necessário para cuidar das pessoas"}
        text={
          "Somos contra posturais radicais, que defendem posicionamentos sem fundamentos.  Isso não é ser “em cima do muro”. Tomar decisões com base em dados e evidências é o melhor jeito para colocar o povo no centro. Nem Estado mínimo, nem máximo. Essas máximas não traduzem a complexidade do nosso tempo. O Estado necessário é economicamente mais livre e socialmente afirmativo e reparativo, cuidando melhor da qualidade de vida de todas as pessoas. O povo em primeiro lugar, não uma ideologia."
        }
        isMobile={isMobile}
        inverted={false}
      />
      <Value
        image={"/img/maiusculo.png"}
        title={"Política com P maiúsculo"}
        text={
          "O Brasil quer mais e por meio da boa Política, pode-se entregar condições melhores ao povo brasileiro. Não buscamos culpados pelos problemas, buscamos soluções. A Política é a  base da democracia e tudo começa pelas boas práticas partidárias, uma marca do PSDB em sua história. Acreditamos na liberdade política, liberdade de manifestação e expressão, participação efetiva, igualdade de voto, diálogo, igualdade política, busca pela paz e prosperidade, e cidadania inclusiva com eleições livres, justas e frequentes, em um âmbito de equilíbrio federativo e regional, de equidade e oportunidade social, e de sustentabilidade em equilíbrio com a produtividade."
        }
        isMobile={isMobile}
        inverted={true}
      />
    </Element>
  );
}
