const API_URL = process.env.REACT_APP_API_URL;

export default function Api() {
  const like = async function (slug) {
    return fetch(API_URL + "/like", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        slug,
      }),
    }).then((data) => {
      return data.json();
    });
  };

  const newsletter = async function (
    name,
    email,
    phone,
    affiliated,
    newsletter
  ) {
    return fetch(API_URL + "/newsletter", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        affiliated,
        newsletter,
      }),
    }).then((data) => {
      return data.json();
    });
  };
  return {
    like,
    newsletter,
  };
}
