import React from "react";

import styled from "styled-components";

const Title = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
`;

const SubTitle = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  margin-bottom: 16px;
`;

// const Bold = styled.span`
//   font-weight: 700;
// `;

export default function Four({ isMobile }) {
  return (
    <>
      <Title style={{ fontSize: isMobile ? 18 : 24 }}>Tucanês</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        Precisamos resgatar a credibilidade da nossa democracia a partir de
        reformas estruturantes, como a administrativa e política, para que
        nossas instituições funcionem melhor. Gestão e tecnologia vão articular
        as interseccionalidades das políticas públicas, trazer eficiência à
        máquina e combater a corrupção.
      </SubTitle>
      {/* <Title style={{ fontSize: isMobile ? 18 : 24 }}>Aprofundamento</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Defender incondicionalmente a democracia</Bold> brasileira,
        alinhado com as raízes fundantes do partido, fortalecendo as
        instituições e a independência entre os poderes, para que os devidos
        pesos e contrapesos aconteçam.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Realizar uma Reforma Administrativa</Bold> empreendedora,
        inovadora e abrangente, que gera custos concentrados e benefícios
        compartilhados.
        <br />- Inclusão de todos os servidores do Estado Brasileiro na
        proposta.
        <br />- Reformulação das carreiras - do processo de entrada à saída,
        permitindo melhorias na seleção, proporcionando treinamento funcional,
        garantindo a permanência dos servidores por competências, o desempenho
        profissional e o desenvolvimento individual.
        <br />- Aumento do rigor da avaliação em estágio probatório.
        <br />- Promoção na mudança do entendimento e na legislação sobre as
        garantias e deveres atribuídos ao servidor em relação ao tema
        estabilidade e simplificação do processo de tramitação administrativa de
        eventual demissão de servidor que cometa ato indisciplinar ou que
        apresente baixa produtividade incompatível com o exigível para o cargo.
        <br />- Transformação do órgão de recursos humanos, para sua atuação ser
        estratégica e incorporar bons indicadores para as respectivas atuações.
        <br />- Racionalização das estruturas, permitindo contratos de gestão e
        parcerias e investimento em tecnologia, para garantir melhores
        resultados, menos erros e mais transparência ao serviço público
        <br />- Desburocratização e digitalização para tornar o serviço público
        mais eficiente e de melhor qualidade
        <br />- Valorização da carreira do servidor público, de maneira cultural
        e institucional. Gratificações devem ocorrer com base em desempenho,
        equilibradas entre os diferentes poderes garantindo que ninguém possa
        ganhar mais do que o teto de remuneração constitucional.
        <br />- Estruturação do regime de trabalho remoto como uma das
        possibilidades de realização do trabalho na administração pública e não
        apenas como contingenciamento de uma situação imposta.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Realizar uma reforma política</Bold> que reaproxime o eleitor de
        seus representantes, amplie a transparência e os canais de participação
        <br />- Aplicação da proporção 50-50 no preenchimento das candidaturas
        proporcionais para cada gênero, com destinação proporcional do fundo
        partidário e do tempo de rádio e TV para ambos os gêneros e autonomia
        das mulheres para o gerenciamento de seus recursos.
        <br />- Garantia do compromisso com a paridade de gênero (50-50) em
        todos os espaços de poder e de tomada de decisão
        <br />- Aprovação do voto distrital misto para reduzir o número de
        partidos, o custo das campanhas eleitorais, aproximando eleitor do seu
        representante , melhorando o debate público e a qualidade do voto do
        eleitor.
        <br />- Transparência do uso do fundo eleitoral: estabelecimento de
        diretrizes, mapeamento de determinantes de alocação, pactuação de
        compromissos e metas e monitoramento dos indicadores.
        <br />- Garantia de proteção de integridade, física, emocional e
        financeira, com alocação de recursos do fundo partidário para combater a
        violência política de gênero e raça
        <br />- Rediscussão do formato de financiamento eleitoral, debatendo as
        externalidades negativas e positivas dos financiamentos público e
        privado.
        <br />- Adaptação das leis eleitorais à era digital e mídias sociais,
        com garantia de transparência na propaganda política online
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Revisar a governança institucional brasileira</Bold>
        <br />- Protagonismo no debate sobre o modelo de Presidencialismo de
        coalizão e do papel disfuncional do legislativo federal na concentração
        de poder da distribuição do orçamento (emendas de relator)
        <br />- Revisão do funcionamento do judiciário, buscando melhores
        resultados do poder, repensando formas de votação, transparência sobre
        todos os dados de agentes e equipamentos públicos para a imprensa,
        pesquisadores e interessados
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Modernizar radicalmente o setor público, com privatizações, concessões
          e parcerias público-privadas
        </Bold>{" "}
        onde necessário, para desonerar o Estado sem perder de vista a qualidade
        dos serviços.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Defender a ética, transparência e bom uso dos recursos públicos
        </Bold>{" "}
        é crucial para garantir a eficiência e eficácia dos serviços públicos.
        <br />- Cumprimento rigoroso dos princípios de transparência,
        publicidade e integridade.
        <br />- Combate e prevenção à corrupção.
        <br />- Compromisso de combate aos privilégios com fortalecimento de
        regras, minando privilégios individuais, e promovendo benefícios à
        maioria dos cidadãos e do Estado.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Instituir o Governo 4.0 com transformação digital e</Bold> foco em
        alavancar eficiência no setor público:
        <br />- Fortalecimento de mecanismos de estímulo à inclusão digital:
        tanto de acesso, quando de orientação e capacitação
        <br />- Ampliação de esforços de simplificação e desburocratização de
        serviços digitais para cidadãos e empreendedores
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Garantir a segurança jurídica</Bold> por meio da:
        <br />- Desburocratização de processos.
        <br />- Despolitização de agências reguladoras.
        <br />- Simplificação das burocracias para os cidadãos: menos regras,
        menos certidões, mais confiança na idoneidade dos cidadãos e punição
        rigorosa para quem fraudar.
        <br />- Garantia de que as instituições públicas incorporem fortes
        princípios de governança e uma visão de longo prazo, para construção de
        confiança com os cidadãos.
        <br />- Revisão da concorrência e do antitruste, estruturas necessárias
        na Quarta Indústria Revolução, garantindo o acesso ao mercado, tanto
        local, quanto internacional.
        <br />- Promoção de mudanças que melhorem a eficiência regulatória para
        efeitos positivos sobre o empreendedorismo, a formalização das empresas,
        o acesso ao crédito e o investimento estrangeiro direto (“FDI”).
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Descentralizar o poder e dar mais autonomia para estados e municípios.
        </Bold>
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Estabelecer uma cultura de acompanhamento e avaliação dos resultados
          de todas as políticas públicas
        </Bold>{" "}
        implementadas pelo Estado, com métricas e indicadores.
        <br />- Criação de mecanismos de transparência, para que o cidadão possa
        acompanhar a execução das políticas públicas e opinar sobre elas105, com
        uso de tecnologia para dar escala à participação.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Regulamentar a Inteligência Artificial (AI), para assegurar a
          manutenção da democracia,{" "}
        </Bold>
        uma vez que ela é, como os humanos produtora de conteúdo e capaz de
        controlar o debate público e potencialmente redesenhar as próprias
        instituições. Pautar as políticas
        <br />- Garantia do progresso e do desenvolvimento de tecnologias,
        assegurando a proteção dos cidadãos e suas instituições, como acontece
        comumente em outras indústrias.
        <br />- Identificação de quem é robô e quem é humano em cada interação
        para impedir aprofundamento de crise de desconfiança
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        #instituições #reformadministrativa #reformapolítica #governodigital
        #gestão #socialdemocracia #anticorrupção #ética #governançabrasileira
        #privatizações #eficiênciadogoverno
      </SubTitle> */}
    </>
  );
}
