import React from "react";

import styled from "styled-components";

const Element = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const Holder = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  /* margin-bottom: 54px; */
`;

const Left = styled.div`
  width: 45%;
  color: #565656;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 67.5px */
`;

const Right = styled.div`
  width: 45%;
  font-weight: 700;
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-top: 32px;
  border-top: 1px solid #b9b9b9;
`;

const Boxes = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const Box = styled.div`
  border-radius: 12px;
  border: 1px solid #b9b9b9;
  padding: 32px;
  text-align: center;
`;

const BoxImage = styled.img`
  margin: 0 auto;
  width: 150px;
  max-width: 100%;
  margin-bottom: 24px;
`;

const BoxTitle = styled.div`
  text-align: center;
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 8px;
`;
const BoxSubTitle = styled.div`
  color: #565656;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;

// const Bold = styled.span`
//   font-weight: 700;
// `;

export default function Process({ width, height, isMobile }) {
  return (
    <>
      <Element
        style={{
          display: isMobile ? "block" : "flex",
          padding: isMobile ? "0px 16px 56px" : "0px 54px 80px",
        }}
      >
        <Left
          style={{
            marginBottom: isMobile ? 32 : 0,
            fontSize: isMobile ? 24 : 54,
            width: isMobile ? "100%" : "45%",
          }}
        >
          O Processo de
          <br /> Construção da
          <br /> Agenda
        </Left>
        <Right
          style={{
            fontSize: isMobile ? 18 : 24,
            width: isMobile ? "100%" : "55%",
          }}
        >
          Esta Agenda Tucana para o Brasil não é um documento definitivo. O
          mundo mudou, estamos organizados em comunidades e redes e esse
          documento é o ponto de partida para nos reconectarmos. É uma proposta
          aberta, pronta para receber novas e bem-vindas contribuições. São
          caminhos que convidam as pessoas para exercer sua cidadania. Caminhos
          moderados, de um partido que tem em seu DNA a oposição aos extremos.
          E, sobretudo, caminhos que trazem grandes sonhos para o Brasil. “É o
          sonho que nos move. É o sonho que nos retira do imobilismo e nos faz
          trabalhar com afinco por dias melhores.”
        </Right>
      </Element>
      <Holder style={{ padding: isMobile ? "0px 16px 56px" : "0px 32px 80px" }}>
        <Boxes style={{ display: isMobile ? "block" : "flex" }}>
          <Box
            style={{
              width: isMobile ? "100%" : "19%",
              marginBottom: isMobile ? 16 : 0,
            }}
          >
            <BoxImage src="/img/escuta.png" />
            <BoxTitle>Escuta da população</BoxTitle>
            <BoxSubTitle>
              Pesquisa quantitativa para escutar as dores da população
              brasileira e sua percepção sobre o partido.
            </BoxSubTitle>
          </Box>
          <Box
            style={{
              width: isMobile ? "100%" : "19%",
              marginBottom: isMobile ? 16 : 0,
            }}
          >
            <BoxImage src="/img/entrevistas.png" />
            <BoxTitle>Entrevista com lideranças</BoxTitle>
            <BoxSubTitle>
              Pesquisa qualitativa em profundidade, considerando amostras
              regionais representativas.
            </BoxSubTitle>
          </Box>
          <Box
            style={{
              width: isMobile ? "100%" : "19%",
              marginBottom: isMobile ? 16 : 0,
            }}
          >
            <BoxImage src="/img/dialogos.png" />
            <BoxTitle>Diálogos Tucanos pelo Brasil</BoxTitle>
            <BoxSubTitle>
              Seminários presenciais regionais para escuta e construção com
              lideranças locais, com o apoio de questionários online.
            </BoxSubTitle>
          </Box>
          <Box
            style={{
              width: isMobile ? "100%" : "19%",
              marginBottom: isMobile ? 16 : 0,
            }}
          >
            <BoxImage src="/img/analise.png" />
            <BoxTitle>Análise de Conteúdos</BoxTitle>
            <BoxSubTitle>
              Consulta a materiais do PSDB, seus segmentos, Secretariado
              Nacional da Mulher e Instituto Teotônio Vilela (ITV) e referências
              de líderes partidários e especialistas.
            </BoxSubTitle>
          </Box>
          <Box
            style={{
              width: isMobile ? "100%" : "19%",
              marginBottom: isMobile ? 16 : 0,
            }}
          >
            <BoxImage src="/img/conteudo.png" />
            <BoxTitle>DNA Tucano</BoxTitle>
            <BoxSubTitle>
              Inspiração nos 17 Objetivos de Desenvolvimento Sustentável (ODS)
              da ONU e compilação em 12 Objetivos para a Agenda Tucana (OAT)
              acessíveis.
            </BoxSubTitle>
          </Box>
        </Boxes>
      </Holder>
    </>
  );
}
