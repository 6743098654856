import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import styled from "styled-components";
import Player from "./player";

import One from "./player/one";
import Two from "./player/two";
import Three from "./player/three";
import Four from "./player/four";
import Five from "./player/five";
import Six from "./player/six";
import Seven from "./player/seven";
import Eight from "./player/eight";
import Nine from "./player/nine";
import Ten from "./player/ten";
import Eleven from "./player/eleven";
import Twelve from "./player/twelve";

const Element = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const Holder = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 54px;
`;

const Left = styled.div`
  width: 45%;
  color: #565656;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 67.5px */
`;

const Right = styled.div`
  width: 45%;
  font-weight: 700;
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-top: 32px;
  border-top: 1px solid #b9b9b9;
`;

// const Bold = styled.div`
//   font-weight: 700;
//   color: #565656;
//   font-style: normal;
//   font-weight: 800;
//   line-height: 150%;
// `;

export default function Objectives({ width, height, isMobile }) {
  const [params] = useSearchParams();

  useEffect(() => {
    console.log(params.get("p"));

    if (params.get("p")) {
      setTimeout(() => {
        try {
          document
            .getElementById(params.get("p"))
            .scrollIntoView({ block: "start", behavior: "smooth" });
        } catch (error) {
          console.log(error);
        }
      }, 250);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <>
      <Element
        style={{
          display: isMobile ? "block" : "flex",
          padding: isMobile ? "56px 16px 56px" : "80px 54px 80px",
        }}
      >
        <Left
          style={{
            marginBottom: isMobile ? 32 : 0,
            fontSize: isMobile ? 24 : 54,
            width: isMobile ? "100%" : "45%",
          }}
        >
          Objetivos da <br />
          Agenda Tucana
        </Left>
        <Right
          style={{
            fontSize: isMobile ? 18 : 24,
            width: isMobile ? "100%" : "55%",
          }}
        >
          Esta agenda de desenvolvimento tem foco em desafios urgentes. Seus
          objetivos macro são claros: (i) oferecer igualdade de oportunidades
          para os brasileiros, reduzir a desigualdade de renda e eliminar a
          pobreza extrema; (ii) promover o crescimento econômico sustentado, com
          inclusão e mobilidade social, gerando mais e melhores empregos; (iii)
          valorizar o patrimônio natural e cultural do Brasil como ativos para o
          desenvolvimento; (iv) defender a dignidade da pessoa humana, a
          igualdade de direitos e oportunidades independentemente de gênero,
          orientação sexual, raça ou religião e (v) defender e aprimorar a
          democracia como sistema de governo e conquista civilizatória.
        </Right>
      </Element>
      <Holder style={{ padding: isMobile ? "0px 16px" : "0px 32px" }}>
        <Player
          number={1}
          title={"Menos Desigualdade e Mais Prosperidade"}
          isMobile={isMobile}
          tweet={
            "O brasileiro merece mais. Não podemos desperdiçar o potencial de cada um de nós. Precisamos acreditar. O centro da atuação política está em garantir água, ambiente saudável, comida no prato, moradia, saúde, educação, crianças plenamente desenvolvidas e oportunidade de trabalho para crescer ao lado de quem amamos. Investir em quem mais precisa, com oportunidades e incentivos, sob medida para famílias, são chave para o sucesso."
          }
          audioImage={"/img/audio_avatar.png"}
          audioTitle={"Tucano do PSDB"}
          audioSubtitle={"Venha escutar o que o PSDB tem a dizer sobre o tema."}
        >
          <One isMobile={isMobile} />
        </Player>
        <Player
          number={2}
          title={"Economia Livre para Crescer"}
          isMobile={isMobile}
          tweet={
            "Para uma transformação efetiva e irreversível, o Brasil precisa crescer com estabilidade e de forma sustentada por longo período. Queremos uma economia sustentável, moderna, competitiva e aberta ao mundo,  com visão transversal de políticas públicas. Apoiar grandes reformas, privatizar e investir onde necessário, além de gerir o país com responsabilidade fiscal, para gerar empregos e combater a inflação. Economia é meio para diminuir as desigualdades de maneira estrutural."
          }
          audioImage={"/img/audio_avatar.png"}
          audioTitle={"Tucano do PSDB"}
          audioSubtitle={"Venha escutar o que o PSDB tem a dizer sobre o tema."}
        >
          <Two isMobile={isMobile} />
        </Player>
        <Player
          number={3}
          title={"Futuro Verde"}
          isMobile={isMobile}
          tweet={
            "Cuidar do meio ambiente é cuidar do futuro da nossa família. A natureza brasileira é rica com suas florestas, rios, animais e minérios, que a colocam em destaque no mundo. É necessário proteger a natureza, lutar contra as mudanças do clima e trabalhar para uma forma de economia que seja sustentável, para garantir o nosso amanhã."
          }
          audioImage={"/img/audio_avatar.png"}
          audioTitle={"Tucano do PSDB"}
          audioSubtitle={"Venha escutar o que o PSDB tem a dizer sobre o tema."}
        >
          <Three isMobile={isMobile} />
        </Player>
        <Player
          number={4}
          title={"Ética e Gestão para Decolar"}
          isMobile={isMobile}
          tweet={
            "Precisamos garantir um país justo onde todos tenham voz. Queremos um governo que funcione bem, sem corrupção, que entenda o tamanho dos desafios que tem em mãos, para conciliar a vontade de todos brasileiros, e que defenda os interesses de quem representa."
          }
          audioImage={"/img/audio_avatar.png"}
          audioTitle={"Tucano do PSDB"}
          audioSubtitle={"Venha escutar o que o PSDB tem a dizer sobre o tema."}
        >
          <Four isMobile={isMobile} />
        </Player>
        <Player
          number={5}
          title={"Nunca pare de estudar"}
          isMobile={isMobile}
          tweet={
            "O destino do povo brasileiro não pode continuar sendo determinado pelo lugar de nascimento, sua etnia ou cor, gênero, ou condição socioeconômica. Buscamos uma educação que garanta a todos os brasileiros uma formação que dê dignidade, gere oportunidades e permita que cada um possa alcançar seus sonhos de vida."
          }
          audioImage={"/img/audio_avatar.png"}
          audioTitle={"Tucano do PSDB"}
          audioSubtitle={"Venha escutar o que o PSDB tem a dizer sobre o tema."}
        >
          <Five isMobile={isMobile} />
        </Player>
        <Player
          number={6}
          title={"Cada Pessoa Importa"}
          isMobile={isMobile}
          tweet={
            "A liberdade de cada pessoa importa. Todos são respeitados para serem quem são e exercerem suas crenças, e protegidos, para que grupos socialmente minorizados tenham as mesmas oportunidades. Esse é o Brasil do futuro."
          }
          audioImage={"/img/audio_avatar.png"}
          audioTitle={"Tucano do PSDB"}
          audioSubtitle={"Venha escutar o que o PSDB tem a dizer sobre o tema."}
        >
          <Six isMobile={isMobile} />
        </Player>
        <Player
          number={7}
          title={"Tranquilidade para as Famílias"}
          isMobile={isMobile}
          tweet={
            "Segurança pública é valorização da vida, é a tranquilidade da sua família. Precisamos trabalhar para impedir e reduzir a violência. Vamos fazer isso repensando as estruturas e utilizando cada vez mais tecnologia."
          }
          audioImage={"/img/audio_avatar.png"}
          audioTitle={"Tucano do PSDB"}
          audioSubtitle={"Venha escutar o que o PSDB tem a dizer sobre o tema."}
        >
          <Seven isMobile={isMobile} />
        </Player>
        <Player
          number={8}
          title={"#Tecnologia Sem Fronteiras"}
          isMobile={isMobile}
          tweet={
            "Tecnologia e inovação são meios para reduzir desigualdades. Transformamos conhecimento em riqueza para todos, com apetite para inovar e investir. O caminho está nas parcerias entre universidades, institutos de pesquisa e empresas com segurança jurídica e de dados."
          }
          audioImage={"/img/audio_avatar.png"}
          audioTitle={"Tucano do PSDB"}
          audioSubtitle={"Venha escutar o que o PSDB tem a dizer sobre o tema."}
        >
          <Eight isMobile={isMobile} />
        </Player>
        <Player
          number={9}
          title={"Saúde Física e Mental"}
          isMobile={isMobile}
          tweet={
            "Mais acesso, mais qualidade, menos fila, mais rapidez e cidadania. Fazer isso por meio do uso de tecnologia e ajuste do que está no papel versus o que é o SUS na prática, para conversar com as necessidades diárias das famílias."
          }
          audioImage={"/img/audio_avatar.png"}
          audioTitle={"Tucano do PSDB"}
          audioSubtitle={"Venha escutar o que o PSDB tem a dizer sobre o tema."}
        >
          <Nine isMobile={isMobile} />
        </Player>
        <Player
          number={10}
          title={"Cultivando o Futuro"}
          isMobile={isMobile}
          tweet={
            "Acreditamos na potência do agronegócio do país: temos um Brasil e um mundo para alimentar, de maneira sustentável e gerando riqueza. Precisamos olhar para o setor no longo prazo. Vamos garantir a propriedade, recursos e investir em infraestrutura. Apoiar o pequeno produtor, para trazer justiça ao campo, assim como garantir que os maiores produtores sejam fortes competidores no mundo."
          }
          audioImage={"/img/audio_avatar.png"}
          audioTitle={"Tucano do PSDB"}
          audioSubtitle={"Venha escutar o que o PSDB tem a dizer sobre o tema."}
        >
          <Ten isMobile={isMobile} />
        </Player>
        <Player
          number={11}
          title={"Choque de Infraestrutura"}
          isMobile={isMobile}
          tweet={
            "As pessoas têm o direito à toda estrutura necessária para viver bem. É preciso garantir água limpa, energia, moradia, transporte e cidades sustentáveis, por meio de boas obras, boas práticas e inovação, fazendo do jeito certo."
          }
          audioImage={"/img/audio_avatar.png"}
          audioTitle={"Tucano do PSDB"}
          audioSubtitle={"Venha escutar o que o PSDB tem a dizer sobre o tema."}
        >
          <Eleven isMobile={isMobile} />
        </Player>
        <Player
          number={12}
          title={"Influência e Protagonismo Internacional"}
          isMobile={isMobile}
          tweet={
            "Fazemos relacionamento amplo com países de maneira ativa e pragmática, com foco na soberania nacional. Temos posições independentes e buscamos a prosperidade para o povo brasileiro e desenvolvimento socioeconômico do país."
          }
          audioImage={"/img/audio_avatar.png"}
          audioTitle={"Tucano do PSDB"}
          audioSubtitle={"Venha escutar o que o PSDB tem a dizer sobre o tema."}
        >
          <Twelve isMobile={isMobile} />
        </Player>
      </Holder>
    </>
  );
}
