import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Helper from "../libs/helper";

import Intro from "../components/intro";
import Tweet from "../components/tweet";
import Objectives from "../components/objectives";
import Process from "../components/process";
import References from "../components/references";
import Footer from "../components/footer";

const Holder = styled.div`
  width: 100%;
  height: 100%;
`;

export default function Home() {
  // instances
  const helper = Helper();

  // states
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
  }, []);

  return (
    <>
      <Holder>
        <Intro width={width} height={height} isMobile={helper.isMobile()} />
        <Tweet width={width} height={height} isMobile={helper.isMobile()} />
        <Objectives
          width={width}
          height={height}
          isMobile={helper.isMobile()}
        />
        <Process width={width} height={height} isMobile={helper.isMobile()} />
        <References
          width={width}
          height={height}
          isMobile={helper.isMobile()}
        />
        <Footer width={width} height={height} isMobile={helper.isMobile()} />
      </Holder>
    </>
  );
}
