import React from "react";
import styled from "styled-components";
const Title = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
`;
const SubTitle = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  margin-bottom: 16px;
`;
// const Bold = styled.span`
//   font-weight: 700;
// `;
export default function Three({ isMobile }) {
  return (
    <>
      <Title style={{ fontSize: isMobile ? 18 : 24 }}> Tucanês </Title>{" "}
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        Somos a principal nação do mundo para liderar a economia verde: a
        sustentabilidade é o passaporte econômico para o futuro do país,
        conciliando desenvolvimento, com conservação e preservação da
        biodiversidade. Promover ações e políticas que visem a preservação do
        meio ambiente, o combate às mudanças climáticas e a transição para uma
        economia verde e sustentável.
      </SubTitle>{" "}
      {/* <Title style={{ fontSize: isMobile ? 18 : 24 }}> Aprofundamento </Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Implementar Política de Governança Climática, com foco no crédito de
          carbono e na vanguarda das maiores economias de baixo carbono global:
        </Bold>
        <br />- Trabalho amplo e forte, agregando todas as fontes de geração de
        gases de efeito estufa: controle do desmatamento, do uso de energia, das
        emissões da indústria, agropecuária feita de forma sustentável e foco na
        área de saneamento, sobretudo na limpeza de rios e córregos urbanos.
        <br />- Manutenção dos compromissos de redução de emissões de gases de
        efeito estufa, conforme acordos internacionais como o Acordo de Paris.
        <br />- Combater desastres ambientais em regiões costeiras, favelas e
        periferias, propriedades rurais; assegurando a proteção, acesso à
        moradia, terra, água potável e saneamento básico para populações
        vulneráveis e de baixa renda
        <br />- Incentivo à Inteligência Artificial para combate às mudanças
        climáticas (identificação, classificação e monitoramento).
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Priorizar a proteção à Amazônia</Bold>
        <br />- Direcionar instituições federais de desenvolvimento que atuam na
        Amazônia, como SUDAM, Banco da Amazônia (BASA) e Superintendência da
        Zona Franca de Manaus (SUFRAMA) à bioeconomia para o uso sustentável da
        floresta e da biodiversidade.
        <br />- Combate ao desmatamento como aliado na criação de alternativas
        de desenvolvimento para a região, gerando emprego e renda com a floresta
        em pé.
        <br />- Combate ao garimpo ilegal
        <br />- Criação da Política Amazônica para os serviços prestados de
        combate aos desastres climáticos para compensação por outros países com
        redistribuição dos ganhos à população, considerando a transição das
        atividades produtivas da região. Adicionalmente à determinação de preço
        de referência ("shadow prices") para preservação ecológica de incentivos
        à preservação.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Adotar práticas sustentáveis em todos os setores da economia,{" "}
        </Bold>{" "}
        produção até o consumo, para minimizar os impactos ambientais
        <br />- Mudança de paradigma do desenvolvimento econômico: passando de
        uma visão antropocêntrica para uma visão ecocêntrica
        <br />- Promoção do desenvolvimento sustentável na agricultura e no
        setor industrial
        <br />- Bioeconomia como oportunidade para transição de uma economia
        mais sustentável e verde, assim como para negócios internacionais:
        transformação da biodiversidade amazônica e do cerrado em fonte geradora
        de biotecnologia.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Mudar instrumentos econômicos, fiscais, tributários e creditícios,{" "}
        </Bold>
        para incentivar a conservação, a correta exploração dos recursos
        naturais e conceber uma economia sintonizada com a sustentabilidade
        <br /> - Introdução de políticas setoriais impulsionadoras do
        desenvolvimento como linha auxiliar das políticas ambientais e do
        desenvolvimento sustentável (a exemplo da Política Nacional de
        Pagamentos por Serviços Ambientais e implementar medidas inovadoras na
        Política de Crédito Rural, adequando sua aplicação ao regramento
        estatuído pela Lei Florestal).
        <br /> - Complementação dos instrumentos de comando e controle
        (licenciamento e fiscalização) com sistemas de planejamento estratégico
        e tecnologicamente avançados
        <br /> - Instituição de taxas de congestionamento ("congestion charge")
        nos grandes centros urbanos em regiões centrais para melhoria da taxa de
        poluição e estímulo de outros modais nos grandes centros.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Adotar Política Nacional de Conservação</Bold>
        <br />- Defesa de uma Política Nacional de Conservação da Fauna, em
        articulação com os Estados, ampliando os mecanismos de proteção para os
        animais domésticos de estimação
        <br />- Defesa da implementação de políticas públicas que promovam a
        conservação dos biomas brasileiros, como a Amazônia, o Cerrado, a Mata
        Atlântica, entre outros, garantindo a proteção da biodiversidade e dos
        serviços ecossistêmicos
        <br />- Estabelecimento de um Programa Nacional de Gestão das Unidades
        de Conservação, consolidando as unidades já existentes e criando novos
        espaços protegidos, notadamente nos biomas nos quais os ecossistemas
        ainda se encontram sub-representados pelo SNUC - Sistema Nacional de
        Unidades de Conservação
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Investir em infraestrutura verde, </Bold>como parques, jardins e
        áreas verdes, para melhorar a qualidade de vida das pessoas e reduzir os
        impactos ambientais
        <br />- Integração dos parques e reservas ao circuito do ecoturismo
        <br />- Aproveitamento das riquezas da floresta com remuneração justa
        por serviços ambientais
        <br />- Reforço do papel das unidades de conservação para a conservação
        da biodiversidade e o ecoturismo
        <br />- Aumento de formas de fiscalizar e executar o Direito de
        Preservação Ambiental
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Promover ajustes nos arranjos institucionais para desenvolvimento
          sustentável efetivo
        </Bold>
        <br />- Governança sistêmica, que atribua responsabilidades ambientais
        às instâncias governamentais encarregadas das políticas setoriais56
        <br />- Fortalecimento e modernização das estruturas para cumprirem suas
        finalidades: Agência Nacional de Águas (ANA), Instituto Brasileiro do
        Meio Ambiente e dos Recursos Naturais Renováveis (Ibama) e Instituto
        Chico Mendes de Conservação da Biodiversidade (ICMBio)
        <br />- Reestruturação e fortalecimento do Conselho Nacional do Meio
        Ambiente (CONAMA) e de outros Conselhos da Administração Federal, para
        garantir seu caráter federativo e a participação efetiva de ONGs,
        Academia e Entidades de Classe
        <br />- Integração entre órgãos licenciadores e o Ministério Público,
        para tornar o processo de licenciamento mais eficiente e eficaz
        <br />
        -- Fortalecimento da capacidade dos municípios: é necessário fortalecer
        os municípios e sua capacidade de produzir riquezas e arrecadação,
        aproveitando melhor os recursos naturais
        <br />
        -- Recriação de centros de excelência de planejamento de longo prazo de
        infraestrutura com banco de projetos do Brasil sobre impacto ambiental
        <br />
        -- Adoção de política internacional e diplomática de vanguarda à
        inclusão de cláusulas referentes à integridade e preservação dos
        ecossistemas e à melhoria das condições de vida das populações mais
        vulneráveis na Organização Mundial de Comércio (OMC).
        <br />- Transformação do SFB (Serviço Florestal Brasileiro) em Agência
        Florestal considerando a magnitude do patrimônio florestal do país,
        dando uso sustentável à produção de madeira industrializada e de
        recursos não madeireiros e execução do CAR (cadastro ambiental rural e o
        PRA (programa de regularização ambiental)
        <br />- Utilização do zoneamento econômico-ecológico como base para
        tomada de decisões, identificando as potencialidades e limitações
        ambientais de uma determinada região.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Incentivar a educação ambiental, </Bold>tanto formal quanto
        informal, para promover a consciência e o engajamento das pessoas em
        práticas sustentáveis e mudar comportamentos da sociedade em relação ao
        uso dos recursos naturais
        <br />- Participação da sociedade civil na tomada de decisões
        relacionadas à proteção do meio ambiente e à promoção da
        sustentabilidade, garantindo a transparência e a responsividade dos
        processos decisórios
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Promover a mobilidade sustentável, </Bold>
        com o incentivo a utilização e integração de diferentes modais, para
        reduzir a poluição e melhorar a qualidade de vida das pessoas
        <br />- Incentivo à cidades inteligentes e sustentáveis, priorizando o
        saneamento, o transporte público, sobretudo o transporte sobre trilhos
        como saída à autodependência do veículo individual
        <br />- Incentivo a utilização de bicicletas e melhoria de calçadas para
        impactar a andabilidade
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Desenvolver energias renováveis, como a solar, eólica e biomassa
        </Bold>{" "}
        e mitigar as mudanças climáticas
        <br />- Energias renováveis e tecnologias limpas: consolidar o Brasil
        como maior matriz energética limpa do mundo. Com adoção de programas de
        incentivo adotando a mesma curva de competitividade do biocombustível
        para a energia solar e eólica.
        <br />- Investimento na cadeia da cana de açúcar / etanol como commodity
        e produto de valor agregado para exportação, com promoção de aumento de
        produtividade do setor sem aumento de hectares plantados e com
        investimentos de tecnologias para melhoria genética, incentivando a
        produção de energia elétrica com queima de bagaço com energia 100%
        renovável.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Promover a economia circular, </Bold>que busca reduzir o
        desperdício e reaproveitar os recursos, para minimizar os impactos
        ambientais e gerar empregos
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Fortalecer a agricultura familiar, </Bold>que é responsável por
        parte considerável da produção de alimentos no país e pode contribuir
        para a conservação dos recursos naturais
        <br />- Simplificação das práticas de manejo sustentável com geração de
        renda por pagamento de taxa por hectares preservados aos proprietários e
        de regiões com biomas estratégicos como Amazônia, Pantanal, Cerrado e
        Mata Atlântica.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Valorizar as populações indígenas e tradicionais, </Bold>
        reconhecendo sua importância na conservação da biodiversidade e no
        convívio harmonioso com a natureza
        <br />- Criação de programa que promova geração de renda por pagamento
        de taxa por hectares preservados aos populações tradicionais e
        ribeirinhas para proteção dos biomas estratégicos como Amazônia,
        Pantanal, Cerrado e Mata Atlântica.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        #educaçãoambiental #economiaverde #políticanacionaldeconservação
        #incentivosfinanceirosparasustentabilidade #mobilidadesustentável
        #energiasrenováveis #economiacircular #agriculturafamiliar
        #popuçaõesindigenas #proteçãoamazonia #ajustesinstitucionais
        #governançaclimática #infraestruturaverde
      </SubTitle> */}
    </>
  );
}
