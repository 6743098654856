import React from "react";

import styled from "styled-components";

const Element = styled.span`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 10px;
  margin-left: -10px;
  padding-right: 10px;
  margin-right: -10px;
  padding-top: 10px;
  margin-top: -10px;
  padding-bottom: 10px;
  margin-bottom: -10px;
`;

export default function Geometry({ children, type, bold }) {
  return (
    <Element
      style={{
        fontWeight: bold ? 700 : 400,
        backgroundImage: `url("/img/geometry_${type ? type : 1}.svg")`,
      }}
    >
      {children}
    </Element>
  );
}
