import React, { useState } from "react";

import styled from "styled-components";
import InputMask from "react-input-mask";

import Api from "../libs/api";

const Element = styled.div`
  background: #0f2bc5;
  width: 100%;
`;

const Holder = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

const Download = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: space-between;
`;

const DownloadText = styled.div`
  text-align: center;
  padding: 16px;
`;

const DownloadForm = styled.div``;

const Title = styled.div`
  color: #fff;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
`;

const SubTitle = styled.div`
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
`;

const Image = styled.img`
  width: 332px;
  max-width: 100%;
`;

const Text = styled.div`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  width: 60%;
  margin: 0 auto;
`;

const Link = styled.a`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-decoration-line: underline;
`;

const LastFooter = styled.div`
  border-top: 1px solid #fff;
`;

const Logos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
`;

const Logo = styled.img`
  /* width: 300px; */
  max-width: 100%;
  margin: 10px;
`;

const Copy = styled.div`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;

const Form = styled.div`
  border-radius: 12px;
  border: 1px solid #fff;
  background: #fff;
`;

const FormTitle = styled.div`
  color: #565656;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-align: center;
`;
const FormRequired = styled.div`
  color: #b9b9b9;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-align: center;
  margin-bottom: 16px;
`;

const FormField = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const FormLabel = styled.div`
  width: 100%;
  color: #565656;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 16px;
`;

const FormInput = styled.input`
  width: 100%;
  border-radius: 49px;
  border: 1px solid #565656;
  color: #565656;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  padding: 16px 32px;
`;

const FormCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 16px;
`;

const Checkbox = styled.div`
  border-radius: 49px;
  background: #cddeff;
  padding: 16px 32px;
  cursor: pointer;
  border: 1px solid #565656;
  background: #fff;
  color: #565656;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-align: left;

  &.checked {
    background: #cddeff;
  }
`;

const FormCheckboxExtra = styled.div`
  color: #565656;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  margin-bottom: 8px;
`;

const Terms = styled.a`
  color: #565656;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  text-decoration-line: underline;
`;

const Button = styled.div`
  width: 100%;
  padding: 16px 0px;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 30px */
  border-radius: 49px;
  background: #0f2bc5;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    border: 2px solid #0f2bc5;
    background: #fff;
    color: #0f2bc5;
  }
`;

const BtnImg = styled.img`
  margin-right: 10px;
`;

export default function Footer({ isMobile }) {
  let api = Api();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();

  const [affiliated, setAffiliated] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [terms, setTerms] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [termsError, setTermsError] = useState(false);

  const [hover, setHover] = useState(false);

  const [loading, setLoading] = useState(false);

  const sendForm = () => {
    if (!name) {
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (!email || !email.includes("@") || email.length < 5) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (!terms) {
      setTermsError(true);
    } else {
      setTermsError(false);
    }

    if (name && email && terms) {
      setLoading(true);

      api.newsletter(name, email, phone, affiliated, newsletter);

      setLoading(false);
      setName("");
      setNameError(false);
      setEmail("");
      setEmailError(false);
      setPhone("");
      setAffiliated(true);
      setNewsletter(false);
      setTerms(false);
      setTermsError(false);

      // if (response.success) {
      window.open("https://brasil2045.com.br/agenda_tucana.pdf", "_blank");
      // }
    }
  };

  return (
    <Element id="download">
      <Holder style={{ padding: isMobile ? "54px 16px" : "80px 32px" }}>
        <Download style={{ display: isMobile ? "block" : "flex" }}>
          <DownloadText style={{ width: isMobile ? "100%" : "40%" }}>
            <Title
              style={{
                marginTop: isMobile ? 54 : 80,
                fontSize: isMobile ? 24 : 54,
                marginBottom: isMobile ? 16 : 24,
              }}
            >
              Conheça o DNA Tucano
            </Title>
            <SubTitle
              style={{
                fontSize: isMobile ? 18 : 24,
                marginBottom: isMobile ? 24 : 40,
              }}
            >
              Baixe o documento completo em PDF
            </SubTitle>
            <Image src="/img/download.png" />
            <Text style={{ width: isMobile ? "100%" : "60%" }}>
              Este é um documento vivo e que está sempre aberto para
              contribuições. Fale com{" "}
              <Link href="mailto:agendatucana@psdb.org.br">
                agendatucana@psdb.org.br
              </Link>
            </Text>
          </DownloadText>
          <DownloadForm
            style={{
              width: isMobile ? "100%" : "55%",
              marginTop: isMobile ? 24 : 0,
              marginBottom: 24,
            }}
          >
            <Form style={{ padding: isMobile ? "24px 40px" : "48px 103px" }}>
              <FormTitle>Preencha os dados a seguir</FormTitle>
              <FormRequired>(* Campos obrigatórios)</FormRequired>
              <FormField>
                <FormLabel style={{ color: nameError ? "#ff0000" : "#565656" }}>
                  Nome *
                </FormLabel>
                <FormInput
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </FormField>
              <FormField>
                <FormLabel
                  style={{ color: emailError ? "#ff0000" : "#565656" }}
                >
                  Email *
                </FormLabel>
                <FormInput
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </FormField>
              <FormField>
                <FormLabel>Telefone</FormLabel>
                <InputMask
                  value={phone}
                  style={{
                    width: "100%",
                    borderRadius: "49px",
                    border: "1px solid #565656",
                    color: "#565656",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "125%",
                    padding: "16px 32px",
                  }}
                  mask="(99) 9999-9999"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </FormField>
              <FormField>
                <FormLabel>Filiado ao partido? *</FormLabel>
                <FormCheckbox style={{ display: isMobile ? "block" : "flex" }}>
                  <Checkbox
                    className={affiliated ? "checked" : ""}
                    style={{ marginBottom: isMobile ? 16 : 0 }}
                    onClick={() => {
                      setAffiliated(true);
                    }}
                  >
                    <input type="checkbox" checked={affiliated} /> Sou
                    filiado(a)
                  </Checkbox>
                  <Checkbox
                    className={!affiliated ? "checked" : ""}
                    onClick={() => {
                      setAffiliated(false);
                    }}
                  >
                    <input type="checkbox" checked={!affiliated} /> Não sou
                    filiado(a)
                  </Checkbox>
                </FormCheckbox>
                <FormCheckboxExtra>
                  <input
                    type="checkbox"
                    checked={newsletter}
                    onClick={() => {
                      setNewsletter(!newsletter);
                    }}
                  />{" "}
                  Quero receber noticias
                </FormCheckboxExtra>
                <FormCheckboxExtra
                  style={{ color: termsError ? "#f00" : "#565656" }}
                >
                  <input
                    type="checkbox"
                    checked={terms}
                    onClick={() => {
                      setTerms(!terms);
                    }}
                  />{" "}
                  Li e concordo com os{" "}
                  <Terms href="/termos.docx" target="_blank">
                    Termos de Uso
                  </Terms>
                </FormCheckboxExtra>
              </FormField>
              <Button
                onClick={loading ? () => {} : sendForm}
                onMouseEnter={() => {
                  setHover(true);
                }}
                onMouseLeave={() => {
                  setHover(false);
                }}
              >
                {hover ? (
                  <BtnImg src="/img/btn_hover.svg" />
                ) : (
                  <BtnImg src="/img/btn.svg" />
                )}
                {loading ? "Aguarde..." : "Fazer Download"}
              </Button>
            </Form>
          </DownloadForm>
        </Download>
        <LastFooter
          style={{
            marginTop: isMobile ? 32 : 60,
            padding: isMobile ? "0px 16px" : "0px 32px",
          }}
        >
          <Logos
            style={{
              marginBottom: isMobile ? 24 : 48,
              marginTop: isMobile ? 24 : 48,
              display: isMobile ? "block" : "flex",
            }}
          >
            <Logo src="/img/brasil.png" style={{ height: 85 }} />
            <Logo src="/img/psdb.png" style={{ height: 70 }} />
            <Logo src="/img/itv.png" style={{ height: 50 }} />
          </Logos>
          <Copy>
            © Copyright 2023 | PSDB - Partido da Social Democracia Brasileira.
            Todos os direitos reservados.
          </Copy>
        </LastFooter>
      </Holder>
    </Element>
  );
}
