import React from "react";

import styled from "styled-components";

const Title = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
`;

const SubTitle = styled.div`
  color: #565656;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  margin-bottom: 16px;
`;

// const Bold = styled.span`
//   font-weight: 700;
// `;

export default function One({ isMobile }) {
  return (
    <>
      <Title style={{ fontSize: isMobile ? 18 : 24 }}>Tucanês</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        O Estado deve atuar para promover um percurso bem sucedido de inclusão
        produtiva e, os atores envolvidos devem acreditar no potencial de todos,
        para ter sucesso na superação da pobreza. Focalização nos grupos mais
        vulneráveis, atendimento personalizado para famílias e comunidades,
        disponibilidade de acesso a serviços e oportunidades, juntamente com
        incentivos são a chave para o sucesso. O uso de dados e a comparação com
        demais países é fundamental para a construção e implementação de
        políticas públicas efetivas.
      </SubTitle>
      {/* <Title style={{ fontSize: isMobile ? 18 : 24 }}>Aprofundamento</Title>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Pautar as políticas sociais na equidade antes da universalidade
        </Bold>
        , garantindo foco na população mais vulnerável para enfrentar desafios
        de assistência social no país, colocando sempre o cidadão no centro.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Focalizar os recursos para quebrar o ciclo da pobreza</Bold>
        : disponibilização de recursos financeiros, alimentícios ou educacionais
        que gerem capacidades para os indivíduos (autonomia e emancipação)
        <br />- Construção de uma agenda da família que respeite e dialogue com
        as necessidades, desejos e possibilidades do grupo familiar, para
        alavancar a superação dos desafios da agenda de assistência social
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Instituir a Lei de Responsabilidade Social</Bold>, assim como
        fizemos com a responsabilidade fiscal. Através de mecanismos de controle
        e equilíbrio, o orçamento federal deverá acionar medidas sempre que
        houver aumento da pobreza e extrema pobreza,{" "}
        <Bold>especialmente quando afetar a primeira infância</Bold> (0 a 6
        anos).
        <br />- Criação do Indicador do Bem-Estar Social (IBES), para
        estabelecimento de limites para a taxa de pobreza do País, de modo que,
        se ultrapassados, os estados recebam recursos de uma linha de
        financiamento público (Fundo de Combate à Pobreza) especialmente criada
        para esse fim.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Criar Programa de Renda Básica</Bold> que garanta ao cidadão
        dignidade em seus direitos e participação na roda da economia
        <br />- Para viabilizar a iniciativa, lucros e dividendos serão taxados
        e o valor a ser definido vai respeitar a Lei de Responsabilidade Fiscal.
        <br />- Serão consideradas as características de cada região, para
        garantir que todos sejam beneficiados de forma justa e efetiva. O
        objetivo é ajudar as famílias em situação vulnerável, com renda per
        capita de até meio salário mínimo e renda total não superior a dois.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Implementar estratégias compensatórias regionais, principalmente Norte
          e Nordeste
        </Bold>
        , para alcançar e gerar efeitos diretos relacionados ao emprego,
        trabalho, renda e sustentabilidade social
        <br />- Proposição de novas formas e estilos de governabilidade para
        promover o desenvolvimento socioeconômico nessas áreas
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Priorizar a primeira infância</Bold>, promovendo a integração de
        programas sociais, de saúde e educação, do período pré-natal até os seis
        anos de idade, para que nossas crianças possam ter, de fato, igualdade
        de oportunidades.
        <br />- Implantação do IMAPI (Índice Município Amigo da Primeira
        Infância) para todo as instâncias federativas (desenvolvido em parceria
        com universidades de saúde pública no Brasil e no Exterior), que utiliza
        indicadores como guia e métrica relacionados à oferta de políticas
        públicas, ações e serviços e práticas familiares voltadas ao
        desenvolvimento infantil que traduzem os cinco domínios do modelo de
        Nutrição de Cuidados recomendado pela Organização Mundial de Saúde e
        UNICEF.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>Priorizar a segurança alimentar</Bold>, em conjunto com o programa
        de renda.
        <br />- Estabelecimento de uma rede de segurança alimentar em todo o
        país, especialmente nos grandes bolsões de pobreza dos centros urbanos.
        Inspirados pelo programa Bom Prato, essa rede será gerida por
        organizações sociais e parcerias, fornecendo refeições frequentes e de
        qualidade a baixo custo para todos os cidadãos que precisarem, sem
        distinção ou necessidade de cadastro
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Investir naqueles que já são atendidos pela rede socioassistencial
          (contempladas no CadÚnico)
        </Bold>{" "}
        nos territórios e em políticas sociais intersetoriais.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Reforçar a unificação cadastral dos programas de assistência social
        </Bold>
        , para garantir o controle do impacto, o compliance da transferência de
        renda e a efetividade das respectivas políticas
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Garantir constitucionalmente o financiamento do Sistema Único de
          Assistência Social (SUAS)
        </Bold>{" "}
        para proporcionar ao cidadão uma rede de proteção social que organize a
        gestão das políticas públicas no território e que faça a interlocução da
        política social com as outras políticas, em especial as equipes de
        educação e saúde.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        <Bold>
          Implantar o Índice de Pobreza Multidimensional (IPM) Global{" "}
        </Bold>
        (desenvolvido pelo Programa das Nações Unidas para o Desenvolvimento
        (PNUD) em parceria com a Oxford Poverty and Human Development Initiative
        (OPHI)), como guia e métrica das ações governamentais intersetoriais,
        para garantir comparabilidade internacional e efetividade dos programas.
      </SubTitle>
      <SubTitle style={{ fontSize: isMobile ? 18 : 24 }}>
        #políticassociaisequânimes #focoterritórioepolíticaintersetorial
        #focoparaquebrarciclopobreza #agendadafamíilia
        #unificaçãocadastralprogramas #indicepobrezamultidimensional
        #segurodestrabalho #rendabásica #segurançaalimentar
        #leideresponsabilidadesocial #primeirainfância #financiamentosuas
      </SubTitle> */}
    </>
  );
}
